import React, { useState } from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';

const GenerateUrl = ({ formData, user_id, path, onClick }) => {
    const [generatedUrl, setGeneratedUrl] = useState('');

    const handleGenerateUrl = () => {
        const { file_type } = formData;
        const { filename } = formData;

        if (!file_type || !user_id || !path) {
            toast.error('Please provide file type, user ID, and path');
            return;
        }

        // Generate URL locally
        const url = `${window.location.origin}/vendor?user_id=${user_id}&file_type=${file_type}&path=${path}&file_name=${filename}`;
        setGeneratedUrl(url);
        toast.success('URL generated successfully!');
        
        // Call the onClick handler from parent component
        if (onClick) {
            onClick();
        }
    };

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(generatedUrl);
        toast.success('URL copied to clipboard!');
    };

    return (
        <>
            <Row className="mt-4">
                <Col md="12" className="d-flex justify-content-start">
                    <Button
                        variant="primary"
                        onClick={handleGenerateUrl}
                        disabled={generatedUrl !== ''} // Disable button after URL is generated
                    >
                        Generate URL
                    </Button>
                </Col>
            </Row>
            {generatedUrl && (
                <Row className="mt-3">
                    <Col md="12">
                        <InputGroup>
                            <FormControl value={generatedUrl} readOnly />
                            <Button variant="outline-secondary" onClick={handleCopyUrl}>
                                Copy URL
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default GenerateUrl;
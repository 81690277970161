import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'; // Import the PLYLoader
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader'; // Import ColladaLoader
import { USDZLoader } from 'three/examples/jsm/loaders/USDZLoader'; // Import USDZLoader

export default function PreviewModal({ show, handleClose, fileType, fileUrl }) {
  const threeContainerRef = useRef(null);

  useEffect(() => {
    let renderer, scene, camera, controls;

    const initializeThreeJs = () => {
      const container = threeContainerRef.current;
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(
        75,
        container.offsetWidth / container.offsetHeight,
        0.1,
        1000
      );
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      container.appendChild(renderer.domElement);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;

      // Add lights
      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      scene.add(ambientLight, directionalLight);

      camera.position.set(0, 1, 5);
    };

    const loadModel = () => {
      const extension = fileUrl.split('.').pop().toLowerCase();

      switch (extension) {
        case 'gltf':
        case 'glb': {
          const loader = new GLTFLoader();
          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
          loader.setDRACOLoader(dracoLoader);
          loader.load(
            fileUrl,
            (gltf) => {
              scene.add(gltf.scene);
              animate();
            },
            undefined,
            (error) => console.error('Error loading GLTF/GLB model:', error)
          );
          break;
        }
        case 'fbx': {
          const loader = new FBXLoader();
          loader.load(
            fileUrl,
            (fbx) => {
              scene.add(fbx);
              animate();
            },
            undefined,
            (error) => console.error('Error loading FBX model:', error)
          );
          break;
        }
        case 'obj': {
          const loader = new OBJLoader();
          loader.load(
            fileUrl,
            (obj) => {
              scene.add(obj);
              animate();
            },
            undefined,
            (error) => console.error('Error loading OBJ model:', error)
          );
          break;
        }
        case 'stl': {
          const loader = new STLLoader();
          loader.load(
            fileUrl,
            (geometry) => {
              const material = new THREE.MeshStandardMaterial({ color: 0x808080 });
              const mesh = new THREE.Mesh(geometry, material);
              scene.add(mesh);
              animate();
            },
            undefined,
            (error) => console.error('Error loading STL model:', error)
          );
          break;
        }
        case 'ply': {
          const loader = new PLYLoader();
          loader.load(
            fileUrl,
            (geometry) => {
              const material = new THREE.MeshStandardMaterial({ color: 0x808080 });
              const mesh = new THREE.Mesh(geometry, material);
              scene.add(mesh);
              animate();
            },
            undefined,
            (error) => console.error('Error loading PLY model:', error)
          );
          break;
        }
        case 'dae': {
          const loader = new ColladaLoader();
          loader.load(
            fileUrl,
            (collada) => {
              scene.add(collada.scene);
              animate();
            },
            undefined,
            (error) => console.error('Error loading DAE model:', error)
          );
          break;
        }
        case 'usdz': {
          const loader = new USDZLoader();
          loader.load(
            fileUrl,
            (usdz) => {
              scene.add(usdz.scene);
              animate();
            },
            undefined,
            (error) => console.error('Error loading USDZ model:', error)
          );
          break;
        }
        default:
          console.error('Unsupported 3D model format:', extension);
      }
    };

    const animate = () => {
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    if (show && fileType === '3dmodel') {
      initializeThreeJs();
      loadModel();
    }

    // Cleanup
    return () => {
      if (renderer) {
        renderer.dispose();
      }
      if (threeContainerRef.current) {
        while (threeContainerRef.current.firstChild) {
          threeContainerRef.current.removeChild(threeContainerRef.current.firstChild);
        }
      }
      if (controls) controls.dispose();
    };
  }, [fileType, fileUrl, show]);

  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fileType === 'image' && (
          <img src={fileUrl} alt="Preview" style={{ width: '100%' }} />
        )}
        {fileType === 'video' && (
          <video controls style={{ width: '100%' }}>
            <source src={fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {fileType === '3dmodel' && (
          <div
            ref={threeContainerRef}
            style={{ width: '100%', height: '400px', background: '#000' }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}


import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../../config/config';
import PreviewModal from '../../../../common/PreviewModal/PreviewModal';
import FilePreview from '../../../../common/FilePreview/FilePreview';

export default function UploadDesignList() {
  const [designs, setDesigns] = useState([]);
  const [preview, setPreview] = useState({ show: false, fileType: '', fileUrl: '' });
  
  const navigate = useNavigate();
  const DESIGN_API_URL = `${API_BASE_URL}/designs`;

  // Fetch design data
  const fetchDesignData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(DESIGN_API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDesigns(response.data);
    } catch (error) {
      console.error('Error fetching design data:', error);
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  useEffect(() => {
    fetchDesignData();
  }, []);

  // Handle deleting a design
  const handleDeleteDesign = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${DESIGN_API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Design deleted successfully'); // Success message
      fetchDesignData();
    } catch (error) {
      toast.error('Error deleting design'); // Error message
      console.error('Error deleting design:', error);
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  const handleEditDesign = (id) => {
    navigate(`/admin/upload-design/edit/${id}`);
  };

  const handleCreateNew = () => {
    navigate('/admin/upload-design/new');
  };

  const handleBackClick = () => {
    navigate('/admin/design');
  };

  const handlePreview = (fileType, fileUrl) => {
    setPreview({ show: true, fileType, fileUrl });
  };


  return (
    <Container fluid className="design-list-container upload-design-container">

        {/* Back Button */}
        {/* <div className="upload-design-back-button">
        <Button variant="outline-light" onClick={handleBackClick}>
          Back
        </Button>
      </div> */}

      {/* File Information Section */}
      {/* <div className="file-info-section justify-content-center h6">
        <div className="file-info-icon">
          <i className="fas fa-check-circle"></i>
        </div>
        <div className="file-name">
          <i className="fas fa-question-circle"></i> HelmetModelRoughDraft.f3d
        </div>
      </div> */}

      {/* Upload Type Section */}
      {/* <div className="upload-type-section">
        <h4>What are you uploading?</h4>
        <Row className="m-3">
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Sketch<br />
              <span>.jpeg</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              3D Model<br />
              <span>.obj</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Render<br />
              <span>.png</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              UI/UX File<br />
              <span>.fig</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Technical Drawings<br />
              <span>.dwg</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Other...<br />
              <span>.other</span>
            </Button>
          </Col>
        </Row>
      </div> */}
      <Row className="top-section">
              <Col md={6}>
                <div className="section-header">
                  <h4>Previous Activity</h4>
                  <div className="menu-icon">
                    <Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../../../assets/admin/requirement/menu.png')} /></Button>
                  </div>
                </div>
                <div className="engineering-divider"></div>
                <div className="previous-activity">
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/VisorFlexTest.png')} className="activity-image" alt="Sketch" />
                    <div className="image-overlay">
                      <div className="image-text">Sketch</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/FabricTest.png')} className="activity-image" alt="3D Model" />
                    <div className="image-overlay">
                      <div className="image-text">3D Model</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/StrengthTest.png')} className="activity-image" alt="Render" />
                    <div className="image-overlay">
                      <div className="image-text">Render</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/StructuralAnalysis.png')} className="activity-image" alt="UI/UX Files" />
                    <div className="image-overlay">
                      <div className="image-text">UI/UX Files</div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="section-header">
                  <h4>Digital Product Documentation</h4>
                  <div className="menu-icon"><Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../../../assets/admin/requirement/menu.png')} /></Button></div>
                </div>
                <div className="engineering-divider"></div>
                <div className="digital-docs">
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/Assets.png')} className="doc-image" alt="Assets" />
                    <div className="image-overlay">
                      <div className="image-text">Technical Drawing</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/DesignDocumentation.png')} className="doc-image" alt="Design Documentation" />
                    <div className="image-overlay">
                      <div className="image-text">Design Documentation</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/TechnicalDataSheets.png')} className="doc-image" alt="Technical Data Sheet" />
                    <div className="image-overlay">
                      <div className="image-text">Technical Data Sheet</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/InstallationGuides.png')} className="doc-image" alt="Installation Guides" />
                    <div className="image-overlay">
                      <div className="image-text">Installation Guides</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/SpecificationsStandards.png')} className="doc-image" alt="Specifications and Standards" />
                    <div className="image-overlay">
                      <div className="image-text">Specifications and Standards</div>
                    </div>
                  </div>
                  <div className="image-card">
                    <img src={require('../../../../../assets/admin/engineering/ProcessDocumentation.png')} className="doc-image" alt="Process Documentation" />
                    <div className="image-overlay">
                      <div className="image-text">Others</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
      <Row className="top-section">
        <Col md={6}>
          <h4>Design Assets</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button variant="light rounded-pill" onClick={handleCreateNew}>Create New</Button>
          <Form.Control type="text" placeholder="Search" className="assets-search ml-3" />
        </Col>
      </Row>
      <div className="assets-divider"></div>
      <Table responsive className="assets-table mt-4">
        <thead>
          <tr>
            <th>Division Name</th>
            <th>Sub Division</th>
            <th>Category</th>
            <th>Sub Category</th>
            <th>Subchild Category</th>
            <th>File</th>
            <th>Description</th>
            <th>File Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {designs.map((design) => (
            <tr key={design._id}>
              <td>{design.division_name?.division_name}</td>
              <td>{design.subdivision_name?.subdivision_name}</td>
              <td>{design.category_name}</td>
              <td>{design.sub_category_name}</td>
              <td>{design.subchildcategory_name}</td>
              <td>
                <FilePreview
                  fileType={design.file_type}
                  fileUrl={design.file_url}
                  onPreview={(type, url) => handlePreview(type, url)} // Pass preview handler
                />
              </td>
              <td>{design.description}</td>
              <td>{design.file_type}</td>
              <td>
                <Button variant="outline-light" size="sm" className="m-1" onClick={() => handleEditDesign(design._id)}>
                  Edit
                </Button>
                <Button variant="outline-danger" size="sm" className="m-1" onClick={() => handleDeleteDesign(design._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
        {/* Preview Modal */}
        <PreviewModal
        show={preview.show}
        handleClose={() => setPreview({ ...preview, show: false })}
        fileType={preview.fileType}
        fileUrl={preview.fileUrl}
      />
    </Container>
  );
}

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import './Vendor.css';
import WhiteLogo from '../../assets/login/images/metakosmos_white_logo.png';
import { API_BASE_URL } from '../../config/config';

export default function Vendor() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [stage, setStage] = useState('Concept');
    const [files, setFiles] = useState([]);

    // Extract query parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user_id = queryParams.get('user_id');
    const file_type = queryParams.get('file_type');
    const path = queryParams.get('path');
    const filename = queryParams.get('file_name');

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const resetForm = () => {
        setName('');
        setEmail('');
        setFiles([]);
        setStage('Concept');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!user_id || !file_type || !path) {
            toast.error('Missing required query parameters.');
            return;
        }

        if (!name || !email) {
            toast.error('Name and Email are required.');
            return;
        }

        if (files.length === 0) {
            toast.error('Please upload at least one file.');
            return;
        }

        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('file_type', file_type);
        formData.append('path', path);
        formData.append('vendor_name', name);
        formData.append('vendor_email', email);
        formData.append('file_name', files[0].name); // Assuming the first file's name is used

        // Append files
        Array.from(files).forEach((file) => {
            formData.append('file', file);
        });

        try {
            const response = await axios.post(`${API_BASE_URL}/temporary`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success('File uploaded successfully!');
            resetForm(); // Reset the form after successful upload
            // console.log(response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to upload file.');
        }
    };

    return (
        <div className="vendor-container">
            {/* Top Section with Logo */}
            <div className="background-image"></div>
            <div className="background-overlay"></div>
            {/* Content Section */}
            <Container fluid className="mt-5">
                <Row>
                    <Col md={12} lg={12}>
                        <Row>
                            <Col md={3} lg={3}></Col>
                            <Col md={9} lg={9}>
                                <div className="justify-content-center">
                                    <img src={WhiteLogo} alt="Metakosmos Logo" />
                                </div>
                            </Col>
                        </Row>
                        <Col md={12} lg={12} className="mt-5">
                            <hr className="underline" style={{ width: '100vw', height: '2px' }} />
                        </Col>
                    </Col>
                    <Col md={12} lg={12}>
                        <Row className="mt-3">
                            <Col md={1} lg={1}></Col>
                            <Col md={9} lg={9}>
                                <h2 className="text-white">
                                    Metakosmos. is requesting a file for{' '}
                                    <span className="highlight">“{filename}”</span>
                                </h2>

                                {/* Form Section */}
                                <Form onSubmit={handleSubmit} className="mt-4">
                                    {/* Step 1: Name */}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-white">Step 1: Enter Your Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="input-field"
                                            placeholder="Enter your name"
                                            required
                                        />
                                    </Form.Group>

                                    {/* Step 2: Email */}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-white">Step 2: Enter Your Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your email"
                                            className="input-field"
                                            required
                                        />
                                    </Form.Group>

                                    {/* Step 3: Choose Stage */}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-white">Step 3: Choose Stage</Form.Label>
                                        <div className="stage-buttons">
                                            {['Concept', 'Prototype', 'Marketing', 'Testing', 'Production'].map(
                                                (stageOption) => (
                                                    <Button
                                                        key={stageOption}
                                                        variant={stage === stageOption ? 'primary' : 'outline-light'}
                                                        onClick={() => setStage(stageOption)}
                                                        className="stage-button"
                                                    >
                                                        {stageOption}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </Form.Group>

                                    {/* Step 4: File Upload */}
                                    <Form.Group className="mb-4">
                                        <Form.Label className="text-white">Step 4: Drop File(s)</Form.Label>
                                        <Form.Control
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                            className="file-input input-field"
                                        />
                                        {/* <p className="text-muted">Accepted File Types: AVI, MP4, MOV</p> */}
                                    </Form.Group>

                                    {/* Submit Button */}
                                    <Button type="submit" className="vendor-submit-button">
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

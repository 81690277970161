
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../../config/config';
import TreeViewCategories from '../../../../common/TreeViewCategories/TreeViewCategories';
import { formatTitleCase } from '../../../../common/StringUtils/StringUtils';
import FileTypeDropdown from '../../../../common/FileTypeDropdown/FileTypeDropdown';
import ApplicationSource from '../../../../common/ApplicationSource/ApplicationSource';
import FileUpload from '../../../../common/FileUpload/FileUpload';
import GenerateUrl from '../../../../common/GenerateUrl/GenerateUrl';

export default function CreateOrUpdateUploadDesign() {
    const [formData, setFormData] = useState({
        division_name: '',
        subdivision_name: '',
        filename: '',
        author: '',
        location: '',
        source: '',
        file_type: '',
        file: null,
        category_name: '',
        sub_category_name: '',
        subchildcategory_name: '',
        subchildchildcategory_name: '',
        description: '',
        start_date: '',
        expected_end_date: '',
        author_type: '',
    });
    const [countries, setCountries] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [selectedFileSource, setSelectedFileSource] = useState(null); // 'pc' or 'google-drive'
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFileSourceModal, setShowFileSourceModal] = useState(false);
    const fileInputRef = useRef(null); // Reference to the file input element
    const [divisions, setDivisions] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [subchildCategories, setSubchildCategories] = useState([]);
    const [showTreeModal, setShowTreeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [showTreeView, setShowTreeView] = useState(!id);

    const [selectedParentCategory, setSelectedParentCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');

    const [isFileUploadDisabled, setIsFileUploadDisabled] = useState(false); // NEW: Disable file input

    const DESIGN_API_URL = `${API_BASE_URL}/designs`;
    const DIVISION_API_URL = `${API_BASE_URL}/requirements/divisions`;
    const SUBDIVISION_API_URL = `${API_BASE_URL}/requirements/subdivisions/division`;

    const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
    const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

    const loadGooglePicker = () => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.onload = () => {
            window.gapi.load('picker', () => { });
        };
        document.body.appendChild(script);
    };

    useEffect(() => {
        // Fetch divisions for dropdown
        const fetchDivisions = async () => {
            try {
                const response = await axios.get(DIVISION_API_URL);
                setDivisions(response.data);
            } catch (error) {
                console.error('Error fetching divisions:', error);
            }
        };

        if (id) {
            fetchDesignData(id);
        }
        loadGooglePicker();
        fetchDivisions();
    }, [id]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                setLoading(true); // Show loader
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryData = response.data.map((country) => ({
                    name: country.name.common,
                    code: country.cca2 || '',
                    shortName: country.cca3 || '',
                }));
                setCountries(countryData.sort((a, b) => a.name.localeCompare(b.name))); // Sort alphabetically
            } catch (error) {
                console.error('Error fetching countries:', error);
            } finally {
                setLoading(false); // Hide loader
            }
        };

        fetchCountries();
    }, []);

    const fetchDesignData = async (designId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${DESIGN_API_URL}/${designId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const design = response.data;
            setFormData({
                ...design,
                division_name: design.division_name?._id || '',
                subdivision_name: design.subdivision_name?._id || '',
                category_name: design.category_name || '',
                subchildcategory_name: design.subchildcategory_name || '',
                subchildchildcategory_name: design.subchildchildcategory_name || '',
                start_date: design.start_date.split('T')[0],
                expected_end_date: design.expected_end_date.split('T')[0],
                datetime: design.datetime.split('T')[0], // Extract date from ISO string
                file: null, // Reset file input for security reasons
            });
            await fetchSubdivisions(design.division_name?._id);
            fetchSubchildCategories(design.category_name, design.sub_category_name, design.subchildcategory_name);
        } catch (error) {
            console.error('Error fetching design data:', error);
            if (error.response && error.response.status === 401) {
                // If the token is invalid or expired, redirect to login
                localStorage.removeItem('token'); // Remove the invalid token
                navigate('/login'); // Redirect to login page
            }
        }
    };

    const fetchSubdivisions = async (divisionId) => {
        try {
            const response = await axios.get(`${SUBDIVISION_API_URL}/${divisionId}`);
            setSubdivisions(response.data);
        } catch (error) {
            console.error('Error fetching subdivisions:', error);
        }
    };

    const fetchSubchildCategories = async (parentcategoryName, categoryName, subcategoryName) => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}/subchild-category/filter?category_name=${parentcategoryName}&sub_category_name=${categoryName}&subchild_category_name=${subcategoryName}`
            );
            setSubchildCategories(response.data);
        } catch (error) {
            console.error('Error fetching subchild categories:', error);
        }
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: '' }); // Clear error on input change

        if (name === 'division_name') {
            setFormData({ ...formData, [name]: value, subdivision_name: '' });
            fetchSubdivisions(value);
        }
    };

    // Handle file input change
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files[0],
        });
        setFormErrors({ ...formErrors, file: '' }); // Clear file error
    };

    const handleSaveDesign = async () => {
        setLoading(true);
        const requestData = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                requestData.append(key, formData[key]);
            }
        });

        if (selectedFileSource === 'google-drive' && selectedFile instanceof Blob) {
            // Append Google Drive file blob
            requestData.append('file', selectedFile, formData.name);
        } else if (selectedFileSource === 'pc' && selectedFile) {
            // Append local file
            requestData.append('file', selectedFile);
        }

        try {
            const token = localStorage.getItem('token');
            if (id) {
                // Update existing design
                await axios.put(`${DESIGN_API_URL}/${id}`, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });
                alert('Design updated successfully!');
            } else {
                // Create a new design
                await axios.post(DESIGN_API_URL, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });
                alert('Design uploaded successfully!');
            }
            navigate('/admin/design');
            toast.success(id ? 'Design updated successfully' : 'Design created successfully');
        } catch (error) {
            toast.error('Error saving design');
            console.error('Error saving design:', error);
            if (error.response && error.response.status === 401) {
                // If the token is invalid or expired, redirect to login
                localStorage.removeItem('token'); // Remove the invalid token
                navigate('/login'); // Redirect to login page
            }
        } finally {
            setLoading(false);
        }
    };

    const handleTreeViewSelect = ({ parentcategory, category, subcategory }) => {
        setFormData((prev) => ({
            ...prev,
            category_name: formatTitleCase(parentcategory.split('_')[0]).toLowerCase(),
            sub_category_name: category,
            subchildcategory_name: subcategory
        }));
        setSelectedParentCategory(parentcategory || ''); // Update parentcategory
        setSelectedCategory(category || '');           // Update category
        setSelectedSubcategory(subcategory || '');     // Update subcategory
        fetchSubchildCategories(formatTitleCase(parentcategory.split('_')[0]).toLowerCase(), category, subcategory);
        setShowTreeModal(false);
        // console.log("parent", parentcategory)
        // console.log("category", category)
        // console.log("subcategory", subcategory)
    };


    const handleGenerateUrl = () => {
        setIsFileUploadDisabled(true); // Disable the file upload
        // Set a dummy image to formData
        const dummyImageUrl = 'https://via.placeholder.com/150';
        const dummyFile = new File([], 'dummy-image.png', { type: 'image/png' });
        // const dummyFile = new File([dummyImage], 'dummy-image.png', {
        //     type: 'image/png',
        // });
        setSelectedFile(dummyFile);
        setFormData((prev) => ({
            ...prev,
            file: dummyFile,
        }));
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user ? user._id : null;
    console.log(user_id)

    return (
        <Container fluid className="create-update-design-container upload-design-container">
            <h5>
                {id ? (
                    // Show breadcrumb if editing
                    <>
                        {formData.category_name && (
                            <>
                                {formatTitleCase(formData.category_name)} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {formData.sub_category_name && (
                            <>
                                {formatTitleCase(formData.sub_category_name)} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {formData.subchildcategory_name && (
                            <>
                                {formatTitleCase(formData.subchildcategory_name)}
                            </>
                        )}
                    </>

                ) : (
                    // Show formData values if creating a new requirement
                    <>
                        {selectedParentCategory && (
                            <>
                                {formatTitleCase(selectedParentCategory.split('_')[0])} &nbsp;&gt;&nbsp;
                                {formatTitleCase(selectedParentCategory.split('_')[1])} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {selectedCategory && (
                            <>
                                {formatTitleCase(selectedCategory)} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {formatTitleCase(selectedSubcategory)}
                    </>
                )}
            </h5>
            <Row className="mt-4">
                <Col md={12}>
                    <h4>{id ? 'Edit Design' : 'Create Design'}</h4>
                </Col>
                <div className='home-divider'></div>
            </Row>

            {showTreeView && (
                <TreeViewCategories onSelect={handleTreeViewSelect} onClose={() => setShowTreeView(false)} />
            )}

            {!showTreeView && (
                <Form>
                    <Row>
                        <Col md="6">
                            <Form.Group controlId="subchildCategoryName">
                                <Form.Label>Subchild Category</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subchildchildcategory_name"
                                    value={formData.subchildchildcategory_name}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.subchildchildcategory_name}
                                >
                                    <option value="">Select Subchild Category</option>
                                    {subchildCategories.map((subchild) => (
                                        <option key={subchild.id} value={subchild.name}>
                                            {subchild.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.subchildchildcategory_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="divisionId">
                                <Form.Label>Division</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="division_name"
                                    value={formData.division_name}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Division</option>
                                    {divisions.map((division) => (
                                        <option key={division._id} value={division._id}>
                                            {division.division_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Form.Group controlId="subdivisionId">
                                <Form.Label>Sub-Division</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subdivision_name"
                                    value={formData.subdivision_name}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!formData.division_name}
                                >
                                    <option value="">Select Sub-Division</option>
                                    {subdivisions.map((subdivision) => (
                                        <option key={subdivision._id} value={subdivision._id}>
                                            {subdivision.subdivision_name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="filename">
                                {/* <Form.Label>Design Name</Form.Label> */}
                                <Form.Label>File Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="filename"
                                    value={formData.filename}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <FileTypeDropdown
                                value={formData.file_type}
                                onChange={handleInputChange}
                                errors={formErrors.file_type}
                            />
                        </Col>
                        <Col md="4">
                            {/* <FileUpload 
                        onFileSelect={(file) => {
                            setSelectedFile(file);
                            setFormData(prev => ({
                                ...prev,
                                file: file
                            }));
                        }}
                        selectedFile={selectedFile}
                        /> */}
                            {!isFileUploadDisabled ? (
                                <FileUpload
                                    onFileSelect={(file) => {
                                        setSelectedFile(file);
                                        setFormData(prev => ({
                                            ...prev,
                                            file: file
                                        }));
                                    }}
                                    selectedFile={selectedFile}
                                    disabled={isFileUploadDisabled}
                                />
                            ) : (
                                <Form.Group controlId="fileType">
                                    <Form.Label>File</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value="File upload disabled - URL generated"
                                        disabled
                                        className="text-muted"
                                    />
                                </Form.Group>
                            )}
                        </Col>
                        <Col md="2" className='mt-2'>
                            {formData.file_type && formData.filename && (
                                <GenerateUrl  formData={formData}
                                    user_id={user_id}
                                    path={'design'}
                                    onClick={handleGenerateUrl}
                                />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <Form.Group controlId="author">
                                <Form.Label>Author</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="author"
                                    value={formData.author}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>


                        <Col md="6">
                            <Form.Group controlId="authorType" >
                                <Form.Label>Author Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="author_type"
                                    value={formData.author_type}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.author_type}
                                >
                                    <option value="">Select Author Type</option>
                                    <option value="Metakosmos">Metakosmos</option>
                                    <option value="Vendor">Vendor</option>
                                    <option value="Contractors">Contractors</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.author_type}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <Form.Group controlId="location">
                                <Form.Label>Select Location</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="location"
                                    value={formData.country_name}
                                    onChange={(e) => {
                                        const selectedCountry = countries.find((country) => country.name === e.target.value);
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            location: selectedCountry?.name || '',
                                        }));
                                    }}

                                >
                                    <option value="">Select Location</option>
                                    {countries.map((country) => (
                                        <option key={country.code} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </Form.Control>

                            </Form.Group>
                        </Col>

                        <Col md="6">
                            <Form.Group controlId="source">
                                <Form.Label>Application Source
                                    <ApplicationSource
                                        tooltipText="Specify whether the application source is hardware or software."
                                    />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="source"
                                    value={formData.source}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <Form.Group controlId="startDate">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="start_date"
                                    value={formData.start_date}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="expectedEndDate">
                                <Form.Label>Expected End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="expected_end_date"
                                    value={formData.expected_end_date}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="description" className="mt-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>

                    <Row className="mt-4">
                        <Col md="12" className="d-flex justify-content-start">
                            <Button variant="primary" onClick={handleSaveDesign}>
                                {id ? 'Update' : 'Save'}
                            </Button>
                            <Button variant="secondary" onClick={() => navigate('/admin/design')} className="ms-3">
                                Cancel
                            </Button>
                        </Col>
                    </Row>

                </Form>

            )}
        </Container>
    );
}

import React from 'react';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { AiOutlineEye } from 'react-icons/ai';

const ApplicationSource = ({ tooltipText }) => {
    const renderPopover = (
        <Popover id="popover-basic">
            <Popover.Body className="text-white">
                {tooltipText || "No tooltip text provided"}
            </Popover.Body>
        </Popover>
    );
    return (
        <OverlayTrigger
            trigger="hover"
            placement="top"
            overlay={renderPopover}
        >
            <a
                href="#"
                className="btn btn-sm btn-outline"
            >
                <AiOutlineEye style={{ cursor: 'pointer', fontSize: '1.2em' }} />
            </a>
        </OverlayTrigger>
    );
};

export default ApplicationSource;

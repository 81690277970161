import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CalendarOutLook.css"; // Create and use a CSS file for styles
import { API_BASE_URL } from "../../../config/config";

const CalendarOutLook = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchCalendarEvents();
  }, []);

  const fetchCalendarEvents = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await axios.get(`${API_BASE_URL}/outlook/calendar-events`);
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching calendar events:", error);
      setError("Failed to load calendar events.");
      setLoading(false);
    }
  };

  return (
    <div className="calendar-container">
      <h1>Calendar Events</h1>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className="calendar-events">
        {events.length > 0 ? (
          events.map((event) => (
            <div key={event.id} className="event-card">
              <h3 className="event-title">{event.subject}</h3>
              <p className="event-time">
                <strong>Start:</strong> {new Date(event.start.dateTime).toLocaleString()}
              </p>
              <p className="event-time">
                <strong>End:</strong> {new Date(event.end.dateTime).toLocaleString()}
              </p>
              <p className="event-location">
                <strong>Location:</strong> {event.location.displayName}
              </p>
              <p className="event-organizer">
                <strong>Organizer:</strong> {event.organizer.emailAddress.name}
              </p>
              <a
                href={event.webLink}
                target="_blank"
                rel="noopener noreferrer"
                className="event-link"
              >
                View in Outlook
              </a>
            </div>
          ))
        ) : (
          !loading && <p>No calendar events found.</p>
        )}
      </div>
    </div>
  );
};

export default CalendarOutLook;

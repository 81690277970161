import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import './Account.css';
import Schedule from '../../common/Schedule/Schedule';
import Calendar from '../Calendar/Calendar';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import CosmicLoader from '../../common/CosmicLoader/CosmicLoader';

export default function Account() {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loading state for the loader
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from local storage
        const response = await axios.get(`${API_BASE_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
        setUpdatedData({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          user_type: response.data.user_type,
          email: response.data.email,
          interests: response.data.interests || '', // Add interests here
          career: response.data.career || '', // Add career here
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          // If the token is invalid or expired, redirect to login
          localStorage.removeItem('token'); // Remove the invalid token
          navigate('/login'); // Redirect to login page
        }
      }
    };

    fetchData();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (e) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const handleSaveClick = async () => {
    if (!updatedData.user_type) {
      setErrors({ user_type: 'Please select a role' });
      return;
    }
    setLoading(true); // Show loader
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/users/me`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(updatedData);
      setEditMode(false);
      setErrors({});
      toast.success('Profile Updated Successfully'); // Success message
    } catch (error) {
      console.error('Error updating user data:', error);
      toast.error('Error While Updating Profile'); // Error message
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setUpdatedData({
      first_name: userData.first_name,
      last_name: userData.last_name,
      user_type: userData.user_type,
      interests: userData.interests || '',
      career: userData.career || '',
    });
    setErrors({});
  };

  if (!userData) return null;

  return (
    <Container fluid className="account-container">
      {loading && (
        <div className="loader-overlay">
          <CosmicLoader />
        </div>
      )}
      <div className="account-background-image"></div>
      <Row className="profile-section">
        <Col md={12} className="profile-details">
          <Image
            src={require('../../../assets/admin/account/image-9.png')}
            roundedCircle
            className="profile-image"
          />
        </Col>
      </Row>
      <Row className="profile-name">
        <Col md={6}>
          <Row>
            <Col md={10}>
              <h2>
                {editMode ? (
                  <Form.Control
                    type="text"
                    name="first_name"
                    className="message-input"
                    value={updatedData.first_name}
                    onChange={handleInputChange}
                  />
                ) : (
                  userData.first_name
                )}{' '}
                {editMode ? (
                  <Form.Control
                    type="text"
                    className="message-input"
                    name="last_name"
                    value={updatedData.last_name}
                    onChange={handleInputChange}
                  />
                ) : (
                  userData.last_name
                )}
              </h2>
              <a href={`mailto:${userData.email}`} className="email-link">
                {userData.email}
              </a>
            </Col>
            {!editMode && (
              <Col md="2">
                <Button
                  variant="link"
                  className="edit-button float-end"
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              </Col>
            )}
          </Row>

          <p className="team-manager">
            {editMode ? (
              <Form.Group controlId="formUserType" className="mb-3">
                <Form.Label>Select Role</Form.Label>
                <Form.Control
                  as="select"
                  disabled={true}
                  className="input-field"
                  name="user_type"
                  value={updatedData.user_type}
                  onChange={handleInputChange}
                  isInvalid={!!errors.user_type}
                >
                  <option value="">Select Role</option>
                  <option value="design">Design</option>
                  <option value="engineering">Engineering</option>
                  <option value="production">Production</option>
                  <option value="quality">Quality</option>
                  <option value="testing">Testing</option>
                  <option value="maintenance">Maintenance</option>
                </Form.Control>
                {errors.user_type && (
                  <Form.Control.Feedback type="invalid">
                    {errors.user_type}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              userData.user_type
            )}
          </p>
          <Row>
            {!editMode && (
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Send a message"
                  className="message-input"
                />
              </Col>
            )}
            {editMode && (
              <Row className="save-cancel-buttons">
                <Col md={6}>
                  <Button variant="primary" onClick={handleSaveClick}>
                    Save
                  </Button>
                  <Button variant="secondary" onClick={handleCancelClick} className="ms-2">
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}

            {!editMode && (
              <Col md={6}>
                <div className="contact-icons float-end">
                  <img
                    className="contact-icon"
                    src={require('../../../assets/admin/account/image 25.png')}
                    alt="Contact Icon 1"
                  />
                  <img
                    className="contact-icon"
                    src={require('../../../assets/admin/account/image 44.png')}
                    alt="Contact Icon 2"
                  />
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={6} className="interests-career">
          <div className="vertical-line"></div>

          <Row>
            <Col md={12}>
              <div className="interests">
                <h5>Interests</h5>
                {editMode ? (
                  <Form.Control
                    as="textarea"
                    name="interests"
                    className="input-field"
                    value={updatedData.interests}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>{userData.interests || 'No interests provided'}</p>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="career">
                <h5>Career</h5>
                {editMode ? (
                  <Form.Control
                    as="textarea"
                    name="career"
                    className="input-field"
                    value={updatedData.career}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>{userData.career || 'No career information provided'}</p>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Schedule />
          {/* <Calendar /> */}
        </Col>
      </Row>
    </Container>
  );
}

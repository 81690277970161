import React, { useEffect, useRef } from 'react';
import ee from '@google/earthengine';

export default function Calls() {
  const mapRef = useRef(null);

  useEffect(() => {
    // Load the Google Earth Engine API and Google Maps API dynamically
    const loadGoogleApis = async () => {
      // Load the Google Earth Engine API
      const earthEngineScript = document.createElement('script');
      earthEngineScript.src = 'https://earthengine.googleapis.com/v1alpha/projects/YOUR_PROJECT_ID/';
      earthEngineScript.async = true;
      document.body.appendChild(earthEngineScript);

      earthEngineScript.onload = () => {
        ee.data.authenticateViaOauth('906014322398-artgi1btptuescmjq3hi7qripbfunroa.apps.googleusercontent.com', () => {
          ee.initialize(null, null, () => {
            console.log('Earth Engine initialized');
          }, (error) => {
            console.error('Earth Engine initialization error: ', error);
          });
        });
      };

      // Load Google Maps JavaScript API
      const googleMapsScript = document.createElement('script');
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBXhNy22YOfz1mMAYT5NXzV2bsFF56GKsU&libraries=geometry,drawing,places`;
      googleMapsScript.async = true;
      document.body.appendChild(googleMapsScript);

      googleMapsScript.addEventListener('load', () => {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 0, lng: 0 }, // Set the initial center of the map
          zoom: 2, // Set the initial zoom level
          mapTypeId: 'satellite', // Use satellite view to simulate Google Earth
        });

        // Example of adding Earth Engine data layer
        const image = ee.Image('COPERNICUS/S2');
        image.getMap({ bands: ['B4', 'B3', 'B2'], max: 3000 }, function (mapId) {
          const eeMapOptions = {
            getTileUrl: function (tile, zoom) {
              return `https://earthengine.googleapis.com/map/${mapId.mapid}/${zoom}/${tile.x}/${tile.y}?token=${mapId.token}`;
            },
            tileSize: new window.google.maps.Size(256, 256),
          };
          const eeMapLayer = new window.google.maps.ImageMapType(eeMapOptions);
          map.overlayMapTypes.push(eeMapLayer);
        });
      });
    };

    loadGoogleApis();

    return () => {
      // Cleanup scripts when component unmounts
      document.body.querySelectorAll('script[src*="earthengine"], script[src*="maps.googleapis"]')
        .forEach(script => document.body.removeChild(script));
    };
  }, []);

  return (
    <div>
      <h2>Calls</h2>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '600px' }}
        title="Google Earth Engine"
      ></div>
    </div>
  );
}

// EngineeringList.js - List Component
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from '../../../../config/config';
import PreviewModal from '../../../common/PreviewModal/PreviewModal';
import FilePreview from '../../../common/FilePreview/FilePreview';
// import './Engineering.css';

export default function EngineeringList() {
  const [expandedRows, setExpandedRows] = useState({});
  const [engineeringData, setEngineeringData] = useState([]);
  const [preview, setPreview] = useState({ show: false, fileType: '', fileUrl: '' });
  const navigate = useNavigate();

  const API_URL = `${API_BASE_URL}/engineering`;

  // Fetch engineering data
  const fetchEngineeringData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEngineeringData(response.data);
    } catch (error) {
      console.error('Error fetching engineering data:', error);
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  const handlePreview = (fileType, fileUrl) => {
    setPreview({ show: true, fileType, fileUrl });
  };


  useEffect(() => {
    fetchEngineeringData();
  }, []);

  const toggleRow = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const renderRows = (items) => {
    return items.map((item) => (
      <React.Fragment key={item._id}>
        <tr>
          <td></td>
          <td>
            <input type="checkbox" className="engineering-custom-checkbox" />
          </td>
          <td onClick={() => toggleRow(item._id)} style={{ cursor: 'pointer' }}>
            {expandedRows[item._id] ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>} {item.name}
          </td>
          <td>{item.fileName}</td>
          <td>{item.fileType}</td>
          <td>
                <FilePreview
                  fileType={item.file_type}
                  fileUrl={item.fileURL}
                  onPreview={(type, url) => handlePreview(type, url)} // Pass preview handler
                />
              </td>
          <td>{item.start_date ? item.start_date.split('T')[0] : ''}</td>
          <td>{item.expected_end_date ? item.expected_end_date.split('T')[0] : ''}</td>
          <td>{item.subchildcategory_name}</td>
          <td>{item.division_name?.division_name}</td>
          <td>{item.subdivision_name?.subdivision_name}</td>
          <td>
            <Button variant="outline-light" size="sm" onClick={() => navigate(`/admin/engineering/edit/${item._id}`)} className="m-1">
              Edit
            </Button>
            <Button variant="outline-danger" size="sm" onClick={() => handleDeleteRequirement(item._id)} className="m-1">
              Delete
            </Button>
          </td>
        </tr>
      </React.Fragment>
    ));
  };

  // Handle deleting a requirement
  const handleDeleteRequirement = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Engineering deleted successfully'); // Success message
      fetchEngineeringData();
    } catch (error) {
      toast.error('Error deleting engineering'); // Error message
      console.error('Error deleting requirement:', error);
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  return (
    <Container fluid className="engineering-container">
      <Row className="top-section">
        <Col md={6}>
          <div className="section-header">
            <h4>Previous Activity</h4>
            <div className="menu-icon">
              <Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../../assets/admin/requirement/menu.png')} /></Button>
            </div>
          </div>
          <div className="engineering-divider"></div>
          <div className="previous-activity">
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/VisorFlexTest.png')} className="activity-image" alt="Visor Flex Test" />
              <div className="image-overlay">
                <div className="image-text">Visor Flex Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/FabricTest.png')} className="activity-image" alt="Fabric Test" />
              <div className="image-overlay">
                <div className="image-text">Fabric Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/StrengthTest.png')} className="activity-image" alt="Strength Test" />
              <div className="image-overlay">
                <div className="image-text">Strength Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/StructuralAnalysis.png')} className="activity-image" alt="Structural Analysis" />
              <div className="image-overlay">
                <div className="image-text">Structural Analysis</div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="section-header">
            <h4>Digital Product Documentation</h4>
            <div className="menu-icon"><Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../../assets/admin/requirement/menu.png')} /></Button></div>
          </div>
          <div className="engineering-divider"></div>
          <div className="digital-docs">
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/Assets.png')} className="doc-image" alt="Assets" />
              <div className="image-overlay">
                <div className="image-text">Assets</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/DesignDocumentation.png')} className="doc-image" alt="Design Documentation" />
              <div className="image-overlay">
                <div className="image-text">Design Documentation</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/TechnicalDataSheets.png')} className="doc-image" alt="Technical Data Sheet" />
              <div className="image-overlay">
                <div className="image-text">Technical Data Sheet</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/InstallationGuides.png')} className="doc-image" alt="Installation Guides" />
              <div className="image-overlay">
                <div className="image-text">Installation Guides</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/SpecificationsStandards.png')} className="doc-image" alt="Specifications and Standards" />
              <div className="image-overlay">
                <div className="image-text">Specifications and Standards</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../../assets/admin/engineering/ProcessDocumentation.png')} className="doc-image" alt="Process Documentation" />
              <div className="image-overlay">
                <div className="image-text">Process Documentation</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="top-section">
        <Col md={6}>
          <h4>Assets</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
        <div className="table-actions mb-2">
           <Button variant="light rounded-pill" onClick={() => navigate('/admin/engineering/new')}>Create New</Button>
              {/* <Button variant="light rounded-pill" onClick={() => setShowTreeModal(true)}>Create New</Button> */}
              {/* <Button variant="light rounded-pill" onClick={handleShowModal}>Upload</Button> */}
              {/* <Button variant="light rounded-pill" onClick={handleShowViewModal}>View</Button> */}
              {/* <Button variant="btn btn-outline-light rounded-pill w-25">Edit</Button> */}
              <Form.Control type="text" placeholder="Search" className="assets-search" />
              <Button variant="btn btn-outline-light rounded-pill" style={{ width: '80px' }}><img src={require('../../../../assets/admin/requirement/menu.png')} /></Button>
              <Button variant="btn btn-outline-light rounded-pill" style={{ width: '80px' }}><img src={require('../../../../assets/admin/requirement/downloadImage.png')} /></Button>
            </div>
          {/* <Button variant="light rounded-pill" onClick={() => navigate('/admin/engineering/new')}>Create New</Button>
          <Form.Control type="text" placeholder="Search" className="assets-search ml-3" /> */}
        </Col>
      </Row>
      <div className="assets-divider"></div>
      <Table responsive className="assets-table mt-4">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Engineering Name</th>
            <th>File Name</th>
            <th>File Type</th>
            <th>File</th>
            <th>Start Date</th>
            <th>Expected End Date</th>
            <th>Subchild Category</th>
            <th>Division</th>
            <th>Sub-Division</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(engineeringData)}
        </tbody>
      </Table>
      <PreviewModal
        show={preview.show}
        handleClose={() => setPreview({ ...preview, show: false })}
        fileType={preview.fileType}
        fileUrl={preview.fileUrl}
      />
    </Container>
  );
}



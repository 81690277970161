import React from 'react';

const FilePreview = ({ fileType, fileUrl, onPreview }) => {
  if (fileType === 'image') {
    return (
      <img
        src={fileUrl}
        alt="File"
        height={50}
        style={{ cursor: 'pointer' }}
        onClick={() => onPreview('image', fileUrl)}
      />
    );
  }

  if (fileType === 'video') {
    return (
      <video
        height={50}
        style={{ cursor: 'pointer' }}
        onClick={() => onPreview('video', fileUrl)}
      >
        <source src={fileUrl} type="video/mp4" />
      </video>
    );
  }

  if (fileType === '3dModel') {
    return (
      <img
        src={require('../../../assets/admin/images/th.jpg')}
        alt="3D Model"
        height={50}
        style={{ cursor: 'pointer' }}
        onClick={() => onPreview('3dmodel', fileUrl)}
      />
    );
  }

  if (['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'].includes(fileType)) {
    return (
      <button
        style={{ cursor: 'pointer' }}
        onClick={() => onPreview('document', fileUrl)}
      >
        View Document
      </button>
    );
  }

  // Default case for unsupported files
  return (
    <a href={fileUrl} download style={{ textDecoration: 'none', color: 'blue' }}>
      Download File
    </a>
  );
};

export default FilePreview;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Register.css';
import WhiteLogo from '../../assets/login/images/metakosmos_white_logo.png';
import { API_BASE_URL } from '../../config/config';
import CosmicLoader from '../common/CosmicLoader/CosmicLoader';
export default function Register() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: '', // Added confirm_password
    user_type: '',
    country_name: '',
    country_code: '',
    country_short_name: '',
    terms_condition: false,
    newsletter: false,
  });

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for the loader
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  // Fetch country data from the API
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoading(true); // Show loader
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryData = response.data.map((country) => ({
          name: country.name.common,
          code: country.cca2 || '',
          shortName: country.cca3 || '',
        }));
        setCountries(countryData.sort((a, b) => a.name.localeCompare(b.name))); // Sort alphabetically
      } catch (error) {
        console.error('Error fetching countries:', error);
      } finally {
        setLoading(false); // Hide loader
      }
    };

    fetchCountries();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleRegisterClick = async (e) => {
    e.preventDefault();

    const newErrors = {};
    // Validate fields
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone_number) newErrors.phone_number = 'Phone number is required';
    if (!formData.password) newErrors.password = 'Password is required';
    if (!formData.confirm_password) newErrors.confirm_password = 'Confirm password is required';
    if (formData.password !== formData.confirm_password) newErrors.confirm_password = 'Passwords do not match';
    if (!formData.user_type) newErrors.user_type = 'User type is required';
    if (!formData.country_name) newErrors.country_name = 'Country name is required';
    if (!formData.terms_condition) newErrors.terms_condition = 'You must accept the terms and conditions';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setApiError('');
      try {
        // Make the API request
        const response = await axios.post(`${API_BASE_URL}/users/register`, formData, {
          headers: { Accept: 'application/json' },
        });

        if (response.status === 201) {
          navigate('/login');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          setApiError(error.response.data.msg);
        } else {
          setApiError('An error occurred while trying to register. Please try again.');
        }
      }
    }
  };

  return (
    <div className={`register-container `}> {/* Add 'loading' class when loading */}
    {/* Show loader overlay when loading */}
    {loading && (
        <div className="loader-overlay">
          <CosmicLoader />
         </div>
      )}
      <div className={`register-content ${loading ? 'loading-opacity' : ''}`}>
      <div className="background-image"></div>
      <div className="background-overlay"></div>
      <Container>
        <Row className="justify-content-center">
          <Col md={12} lg={12}>
            <div className="text-center mb-4">
              <img src={WhiteLogo} alt="Metakosmos Logo" className="mb-4 ml-auto mr-auto" />
              <p className="tagline text-light">Unified Receptive Production System</p>
            </div>
            <Form className="register-form">
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formFirstName" className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      className="input-field"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      isInvalid={!!errors.first_name}
                    />
                    {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formLastName" className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      className="input-field"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      isInvalid={!!errors.last_name}
                    />
                    {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="example@metakosmos.au"
                      className="input-field"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formPhoneNumber" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      className="input-field"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                      isInvalid={!!errors.phone_number}
                    />
                    {errors.phone_number && <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>}
                  </Form.Group>
                </Col>
                
                <Col md={6}>
    <Form.Group controlId="formPassword" className="mb-3">
      <Form.Label>Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="••••••••"
        className="input-field"
        name="password"
        value={formData.password}
        onChange={handleChange}
        isInvalid={!!errors.password}
      />
      {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
    </Form.Group>
  </Col>

  <Col md={6}>
    <Form.Group controlId="formConfirmPassword" className="mb-3">
      <Form.Label>Confirm Password</Form.Label>
      <Form.Control
        type="password"
        placeholder="••••••••"
        className="input-field"
        name="confirm_password"
        value={formData.confirm_password}
        onChange={handleChange}
        isInvalid={!!errors.confirm_password}
      />
      {errors.confirm_password && <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>}
    </Form.Group>
  </Col>

              </Row>

              <Form.Group controlId="formUserType" className="mb-3">
                <Form.Label>Select Role</Form.Label>
                <Form.Control
                  as="select"
                  className="input-field"
                  name="user_type"
                  value={formData.user_type}
                  onChange={handleChange}
                  isInvalid={!!errors.user_type}
                >
                  <option value="">Select Role</option>
                  <option value="design">Design</option>
                  <option value="engineering">Engineering</option>
                  <option value="production">Production</option>
                  <option value="quality">Quality</option>
                  <option value="testing">Testing</option>
                  <option value="maintenance">Maintenance</option>
                  <option value="project manager">Project Manager</option>
                  
                </Form.Control>
                {errors.user_type && <Form.Control.Feedback type="invalid">{errors.user_type}</Form.Control.Feedback>}
              </Form.Group>

              <Form.Group controlId="formCountry" className="mb-3">
                <Form.Label>Select Country</Form.Label>
                <Form.Control
                  as="select"
                  className="input-field"
                  name="country_name"
                  value={formData.country_name}
                  onChange={(e) => {
                    const selectedCountry = countries.find((country) => country.name === e.target.value);
                    setFormData((prevData) => ({
                      ...prevData,
                      country_name: selectedCountry?.name || '',
                      country_code: selectedCountry?.code || '',
                      country_short_name: selectedCountry?.shortName || '',
                    }));
                  }}
                  isInvalid={!!errors.country_name}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
                {errors.country_name && <Form.Control.Feedback type="invalid">{errors.country_name}</Form.Control.Feedback>}
              </Form.Group>

              <Form.Group controlId="formTerms" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="I accept the terms and conditions"
                  className="text-light"
                  name="terms_condition"
                  checked={formData.terms_condition}
                  onChange={handleChange}
                  isInvalid={!!errors.terms_condition}
                />
                {errors.terms_condition && <Form.Control.Feedback type="invalid">{errors.terms_condition}</Form.Control.Feedback>}
              </Form.Group>

              <Form.Group controlId="formNewsletter" className="mb-4">
                <Form.Check
                  type="checkbox"
                  label="Subscribe to newsletter"
                  className="text-light"
                  name="newsletter"
                  checked={formData.newsletter}
                  onChange={handleChange}
                />
              </Form.Group>

              {apiError && <div className="text-danger mb-3">{apiError}</div>}

              <div className="register-button-container" onClick={handleRegisterClick}>
                <img
                  src={require('../../assets/login/images/image-5.png')}
                  alt="Enter Button Background"
                  className="register-button-image"
                />
                <span className="register-button-text">Register</span>
              </div>
            </Form>

            <div className="text-center mt-3">
              <p className="text-light">
                Already have an account?{' '}
                <Link to="/login" className="text-primary">
                  Click here to login
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
}

// CreateOrUpdateEngineering.js - Form Component
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../../../../config/config';
import TreeViewCategories from '../../../common/TreeViewCategories/TreeViewCategories';
import { formatTitleCase } from '../../../common/StringUtils/StringUtils';
import ApplicationSource from '../../../common/ApplicationSource/ApplicationSource';
import FileTypeDropdown from '../../../common/FileTypeDropdown/FileTypeDropdown';
// import './Engineering.css';

export default function CreateOrUpdateEngineering() {
    const [countries, setCountries] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [subchildCategories, setSubchildCategories] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [selectedFileSource, setSelectedFileSource] = useState(null); // 'pc' or 'google-drive'
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFileSourceModal, setShowFileSourceModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedParentCategory, setSelectedParentCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const fileInputRef = useRef(null); // Reference to the file input element
    const [formData, setFormData] = useState({
        name: '',
        subchildcategory_name: '',
        file_type: '',
        file: null,
        division_name: '',
        subdivision_name: '',
        start_date: '',
        expected_end_date: '',
        description: '',
    });

    const navigate = useNavigate();
    const { id } = useParams(); // To determine if editing an existing requirement
    const [showTreeView, setShowTreeView] = useState(!id);
    const { state } = useLocation(); // To receive category/subcategory info if creating new

    const API_URL = `${API_BASE_URL}/engineering`;
    const DIVISION_API_URL = `${API_BASE_URL}/requirements/divisions`;
    const SUBDIVISION_API_URL = `${API_BASE_URL}/requirements/subdivisions/division`;

    const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
    const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

    const loadGooglePicker = () => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.onload = () => {
            window.gapi.load('picker', () => { });
        };
        document.body.appendChild(script);
    };

    useEffect(() => {
        loadGooglePicker();
        fetchDivisions();
        if (id) {
            fetchEngineeringData(id);
        } else if (state) {
            fetchSubchildCategories(state.category, state.subcategory);
            setFormData({
                ...formData,
                category_name: state.category,
                sub_category_name: state.subcategory,
            });
        }
    }, [id, state]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                setLoading(true); // Show loader
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryData = response.data.map((country) => ({
                    name: country.name.common,
                    code: country.cca2 || '',
                    shortName: country.cca3 || '',
                }));
                setCountries(countryData.sort((a, b) => a.name.localeCompare(b.name))); // Sort alphabetically
            } catch (error) {
                console.error('Error fetching countries:', error);
            } finally {
                setLoading(false); // Hide loader
            }
        };

        fetchCountries();
    }, []);

    // Fetch divisions for the dropdown
    const fetchDivisions = async () => {
        try {
            const response = await axios.get(DIVISION_API_URL);
            setDivisions(response.data);
        } catch (error) {
            console.error('Error fetching divisions:', error);
        }
    };

    // Fetch subdivisions based on selected division
    const fetchSubdivisions = async (divisionId) => {
        try {
            const response = await axios.get(`${SUBDIVISION_API_URL}/${divisionId}`);
            setSubdivisions(response.data);
        } catch (error) {
            console.error('Error fetching subdivisions:', error);
        }
    };

    // Fetch subchild categories based on selected category and subcategory
    const fetchSubchildCategories = async (parentcategoryName, categoryName, subcategoryName) => {
        try {
            const response = await axios.get(
                `${API_BASE_URL}/subchild-category/filter?category_name=${parentcategoryName}&sub_category_name=${categoryName}&subchild_category_name=${subcategoryName}`
            );
            setSubchildCategories(response.data);
        } catch (error) {
            console.error('Error fetching subchild categories:', error);
        }
    };

    // Fetch engineering data if editing
    const fetchEngineeringData = async (engineeringId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/${engineeringId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const engineering = response.data;
            setFormData({
                name: engineering.name,
                division_name: engineering.division_name?._id || '',
                subdivision_name: engineering.subdivision_name?._id || '',
                category_name: engineering.category_name || '',
                sub_category_name: engineering.sub_category_name || '',
                subchildcategory_name: engineering.subchildcategory_name || '',
                subchildchildcategory_name: engineering.subchildchildcategory_name || '',
                author: engineering.author || '',
                author_type: engineering.author_type || '',
                location: engineering.location || '',
                source: engineering.source || '',
                file_type: engineering.file_type || '',
                // fileName: engineering.fileName || '',
                start_date: engineering.start_date.split('T')[0] || '',
                expected_end_date: engineering.expected_end_date.split('T')[0] || '',
                description: engineering.description || '',
                file: null, // File cannot be pre-filled
            });
            fetchSubdivisions(engineering.division_name?._id);
            fetchSubchildCategories(engineering.category_name, engineering.sub_category_name, engineering.subchildcategory_name);
        } catch (error) {
            console.error('Error fetching engineering data:', error);
            if (error.response && error.response.status === 401) {
                // If the token is invalid or expired, redirect to login
                localStorage.removeItem('token'); // Remove the invalid token
                navigate('/login'); // Redirect to login page
            }
        }
    };

    // Handle File Source Selection
    const handleFileSourceSelect = (source) => {
        setSelectedFileSource(source);
        setShowFileSourceModal(false);

        if (source === 'pc') {
            if (fileInputRef.current) {
                fileInputRef.current.click(); // Trigger file input click
            } else {
                console.error('File input ref is not available');
            }
        } else if (source === 'google-drive') {
            handleGoogleDrivePicker(); // Replace with your Google Drive logic
        }
    };

    // Handle Google Drive Picker
    const handleGoogleDrivePicker = () => {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: async (response) => {
                if (response.error) {
                    console.error('Error:', response.error);
                    return;
                }
                const oauthToken = response.access_token;

                const picker = new window.google.picker.PickerBuilder()
                    .addView(window.google.picker.ViewId.DOCS)
                    .setOAuthToken(oauthToken)
                    .setDeveloperKey(API_KEY)
                    .setCallback(async (data) => {
                        if (data.action === window.google.picker.Action.PICKED) {
                            const file = data.docs[0];
                            setSelectedFile(file); // Save the file metadata
                            try {
                                const fileBlob = await fetchGoogleDriveFile(file.id, oauthToken);
                                if (fileBlob) {
                                    setSelectedFile(fileBlob);
                                }
                            } catch (error) {
                                console.error('Error fetching file blob:', error);
                            }
                        }
                    })
                    .build();

                picker.setVisible(true);
            },
        });
        tokenClient.requestAccessToken();
    };

    // Function to fetch the actual file content from Google Drive
    const fetchGoogleDriveFile = async (fileId, accessToken) => {
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching file from Google Drive, status: ${response.status}`);
            }

            return await response.blob(); // Return the file blob
        } catch (error) {
            console.error('Error downloading file:', error);
            return null;
        }
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: '' }); // Clear error on input change

        if (name === 'division_name') {
            setFormData({ ...formData, [name]: value, subdivision_name: '' });
            fetchSubdivisions(value);
        }
    };

    // Handle file input change
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files[0],
        });
        setFormErrors({ ...formErrors, file: '' }); // Clear file error
    };

    // Handle save engineering (create or update)
    const handleSaveEngineering = async () => {
        const requestData = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key] !== null) {
                requestData.append(key, formData[key]);
            }
        });

        if (selectedFileSource === 'google-drive' && selectedFile instanceof Blob) {
            // Append Google Drive file blob
            requestData.append('file', selectedFile, formData.name);
        } else if (selectedFileSource === 'pc' && selectedFile) {
            // Append local file
            requestData.append('file', selectedFile);
        }

        try {
            const token = localStorage.getItem('token');
            if (id) {
                // Update existing engineering
                await axios.put(`${API_URL}/${id}`, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });
            } else {
                // Create new engineering
                await axios.post(API_URL, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
            navigate('/admin/engineering'); // Redirect to engineering list
            toast.success(id ? 'Engineering updated successfully' : 'Engineering created successfully');
        } catch (error) {
            if (error.response && error.response.status === 422) {
                const errors = {};
                error.response.data.errors.forEach((err) => {
                    const key = err.path || err.param;
                    if (key) {
                        errors[key] = err.msg;
                    }
                });
                setFormErrors(errors);
            } else {
                toast.error('Error saving engineering');
                console.error('Error saving engineering:', error);
                if (error.response && error.response.status === 401) {
                    // If the token is invalid or expired, redirect to login
                    localStorage.removeItem('token'); // Remove the invalid token
                    navigate('/login'); // Redirect to login page
                }
            }
        }
    };

    // Handle tree view selection
    // const handleTreeViewSelect = ({ category, subcategory }) => {
    //     fetchSubchildCategories(category, subcategory);
    //     setFormData({
    //         ...formData,
    //         category_name: category,
    //         sub_category_name: subcategory,
    //     });
    //     setShowTreeView(false);
    // };

    const handleTreeViewSelect = ({ parentcategory, category, subcategory }) => {
        setFormData((prev) => ({
            ...prev,
            category_name: formatTitleCase(parentcategory.split('_')[0]).toLowerCase(),
            sub_category_name: category,
            subchildcategory_name: subcategory
        }));
        setSelectedParentCategory(parentcategory || ''); // Update parentcategory
        setSelectedCategory(category || '');           // Update category
        setSelectedSubcategory(subcategory || '');     // Update subcategory
        fetchSubchildCategories(formatTitleCase(parentcategory.split('_')[0]).toLowerCase(), category, subcategory);
        setShowTreeView(false);
        // console.log("parent", parentcategory)
        // console.log("category", category)
        // console.log("subcategory", subcategory)
    };

    return (
        <Container fluid className="create-update-engineering-container engineering-container">
            <h5>
                {id ? (
                    // Show breadcrumb if editing
                    <>
                        {formData.category_name && (
                            <>
                                {formatTitleCase(formData.category_name)} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {formData.sub_category_name && (
                            <>
                                {formatTitleCase(formData.sub_category_name)} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {formData.subchildcategory_name && (
                            <>
                                {formatTitleCase(formData.subchildcategory_name)}
                            </>
                        )}
                    </>

                ) : (
                    // Show formData values if creating a new requirement
                    <>
                        {selectedParentCategory && (
                            <>
                                {formatTitleCase(selectedParentCategory.split('_')[0])} &nbsp;&gt;&nbsp;
                                {formatTitleCase(selectedParentCategory.split('_')[1])} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {selectedCategory && (
                            <>
                                {formatTitleCase(selectedCategory)} &nbsp;&gt;&nbsp;
                            </>
                        )}
                        {formatTitleCase(selectedSubcategory)}
                    </>
                )}
            </h5>

            <Row className="mt-4">
                <Col md={12} className="d-flex justify-content-between align-items-center">
                    <h4>{id ? 'Edit Engineering' : 'Create Engineering'}</h4>
                </Col>
                <div className="home-divider"></div>
            </Row>

            {showTreeView && (
                <TreeViewCategories onSelect={handleTreeViewSelect} onClose={() => setShowTreeView(false)} />
            )}
            {!showTreeView && (
                <Form>


                    <Row>
                        <Col md="6">
                            <Form.Group controlId="subchildCategoryName">
                                <Form.Label>Subchild Category</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subchildchildcategory_name"
                                    value={formData.subchildchildcategory_name}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.subchildchildcategory_name}
                                >
                                    <option value="">Select Subchild Category</option>
                                    {subchildCategories.map((subchild) => (
                                        <option key={subchild.id} value={subchild.name}>
                                            {subchild.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.subchildchildcategory_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="divisionId" >
                                <Form.Label>Division</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="division_name"
                                    value={formData.division_name}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.division_name}
                                >
                                    <option value="">Select Division</option>
                                    {divisions.map((division) => (
                                        <option key={division._id} value={division._id}>
                                            {division.division_name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.division_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="subdivisionId" className="mt-3">
                                <Form.Label>Sub-Division</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subdivision_name"
                                    value={formData.subdivision_name}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!formData.division_name} // Disable dropdown if no division is selected
                                    isInvalid={!!formErrors.subdivision_name}
                                >
                                    <option value="">Select Sub-Division</option>
                                    {subdivisions.map((subdivision) => (
                                        <option key={subdivision._id} value={subdivision._id}>
                                            {subdivision.subdivision_name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.subdivision_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="engineeringName" className="mt-3">
                                <Form.Label>File Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FileTypeDropdown
                                value={formData.file_type}
                                onChange={handleInputChange}
                                errors={formErrors.file_type}
                            />
                            {/* <Form.Group controlId="file_type" className="mt-3">
                                <Form.Label>File Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="file_type"
                                    value={formData.file_type}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.file_type}
                                >
                                    <option value="">Select File Type</option>
                                    <option value="image">Image</option>
                                    <option value="video">Video</option>
                                    <option value="3dModel">3D Model</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.file_type}
                                </Form.Control.Feedback>
                            </Form.Group> */}
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="file_type" className="mt-3">
                                <Form.Label>File</Form.Label>
                                <div>
                                    <Button className='form-control' onClick={() => setShowFileSourceModal(true)}>Choose File</Button>
                                </div>
                                {selectedFile && (
                                    <div>
                                        <p>Selected File: {selectedFile.name}</p>
                                    </div>
                                )}
                                {/* Hidden file input */}
                                <input
                                    className='form-control'
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <Form.Group controlId="author">
                                <Form.Label>Author</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="author"
                                    value={formData.author}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>


                        <Col md="6">
                            <Form.Group controlId="authorType" >
                                <Form.Label>Author Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="author_type"
                                    value={formData.author_type}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.author_type}
                                >
                                    <option value="">Select Author Type</option>
                                    <option value="Metakosmos">Metakosmos</option>
                                    <option value="Vendor">Vendor</option>
                                    <option value="Contractors">Contractors</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.author_type}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            {/* <Form.Group controlId="location">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group> */}
                            <Form.Group controlId="location">
                                <Form.Label>Select Location</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="location"
                                    value={formData.location}
                                    onChange={(e) => {
                                        const selectedCountry = countries.find((country) => country.name === e.target.value);
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            location: selectedCountry?.name || '',
                                        }));
                                    }}

                                >
                                    <option value="">Select Location</option>
                                    {countries.map((country) => (
                                        <option key={country.code} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </Form.Control>

                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="source">
                                <Form.Label>Application Source
                                    <ApplicationSource
                                        tooltipText="Specify whether the application source is hardware or software."
                                    />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="source"
                                    value={formData.source}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="startDate" className="mt-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="start_date"
                                    value={formData.start_date}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.start_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.start_date}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="expectedEndDate" className="mt-3">
                                <Form.Label>Expected End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="expected_end_date"
                                    value={formData.expected_end_date}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.expected_end_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.expected_end_date}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="description" className="mt-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={!!formErrors.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formErrors.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md="12" className="d-flex justify-content-start">
                            <Button variant="primary" onClick={handleSaveEngineering}>
                                {id ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => navigate('/admin/engineering')}
                                className="ms-3" // Adds margin-start to create space
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>

                </Form>


            )}

            <Modal show={showFileSourceModal} onHide={() => setShowFileSourceModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select File Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-around">
                        <Button variant="primary" onClick={() => handleFileSourceSelect('pc')}>
                            Upload from PC
                        </Button>
                        <Button variant="secondary" onClick={() => handleFileSourceSelect('google-drive')}>
                            Select from Google Drive
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

// RequirementsList.js - List Component
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../config/config';
import { NavLink, useNavigate } from 'react-router-dom';
import PreviewModal from '../../../common/PreviewModal/PreviewModal';
import FilePreview from '../../../common/FilePreview/FilePreview';
export default function RequirementsList() {
  const [requirements, setRequirements] = useState([]);
  const [preview, setPreview] = useState({ show: false, fileType: '', fileUrl: '' });
  const navigate = useNavigate();

  const API_URL = `${API_BASE_URL}/requirements`;

  // Fetch requirements
  const fetchRequirements = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRequirements(response.data);
    } catch (error) {
      console.error('Error fetching requirements:', error);
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  useEffect(() => {
    fetchRequirements();
  }, []);

  // Handle deleting a requirement
  const handleDeleteRequirement = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Requirement deleted successfully'); // Success message
      fetchRequirements();
    } catch (error) {
      toast.error('Error deleting requirement'); // Error message
      console.error('Error deleting requirement:', error);
      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  const handlePreview = (fileType, fileUrl) => {
    setPreview({ show: true, fileType, fileUrl });
  };

  return (
    <Container fluid className="requirement-container">
      <Row className="top-section">
        <Col md={6}>
          <h4>Requirements</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center requirement-buttons">
        <Button variant="light" onClick={() => navigate('/admin/requirements/new')}>Create New</Button>
        </Col>
      </Row>
      <div className="requirement-divider mb-4"></div>

      <Table responsive className="requirement-table">
        <thead>
          <tr>
            <th>Division Name</th>
            <th>Sub Division</th>
            <th>Source</th>
            <th>Name</th>
            <th>File Type</th>
            <th>File</th>
            <th>Creator</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requirements.map((requirement) => (
            <tr key={requirement._id}>
              <td>{requirement.division_name?.division_name}</td>
              <td>{requirement.subdivision_name?.subdivision_name}</td>
              <td>{requirement.source}</td>
              <td>{requirement.name}</td>
              <td>{requirement.file_type}</td>
              <td>
                <FilePreview
                  fileType={requirement.file_type}
                  fileUrl={requirement.file_url}
                  onPreview={(type, url) => handlePreview(type, url)} // Pass preview handler
                />
              </td>
              <td>{requirement.created_by?.first_name}</td>
              <td>
                <Button
                  variant="outline-light"
                  size="sm"
                  onClick={() => navigate(`/admin/requirements/edit/${requirement._id}`)}
                  className="m-1"
                >
                  Edit
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDeleteRequirement(requirement._id)}
                  className="m-1"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
          {/* Preview Modal */}
          <PreviewModal
        show={preview.show}
        handleClose={() => setPreview({ ...preview, show: false })}
        fileType={preview.fileType}
        fileUrl={preview.fileUrl}
      />
    </Container>
  );
}

import React, { useState, useRef } from 'react';
import './Dashboard.css'; // Add custom CSS here for styling
import ProgressModal from './ProgressModal/ProgressModal';
export default function Dashboard() {

    const items = [
        {
            name: "Sensor Suit",
            color: "#8a2be2",
            background: '#8A34FF33',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            }
        },
        { 
            name: "Bladder", 
            color: "#8a2be2", 
            background: '#8A34FF33',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            } 
        },
        { 
            name: "Exoskeleton", 
            color: "#ff0000", 
            background: '#FF344C4D',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            } 
        },
        { 
            name: "Exosuits", 
            color: "#8a2be2", 
            background: '#8A34FF33',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            } 
        },
        { 
            name: "Helmet", 
            color: "#8a2be2", 
            background: '#8A34FF33',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            } 
        },
        { 
            name: "Boots", 
            color: "#ffd700", 
            background: '#FFEB3433',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            } 
        },
        { 
            name: "Life Support", 
            color: "#8a2be2", 
            background: '#8A34FF33',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            }
         },
        { 
            name: "Thermal Management", 
            color: "#ffd700", 
            background: '#FFEB3433',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            }
         },
        { 
            name: "Power", 
            color: "#8a2be2", 
            background: '#8A34FF33',
            items: {
                0: "Technical feasibility study",
                1: "Concept — Conops",
                2: "MBSE Framework Requirements",
                3: "Design — 3D CAD",
                4: "Sub-Assembly Design",
                5: "Eng. Product Data",
                6: "Eng. Simulations",
                7: "Design for Manufacturing",
                8: "Manufacturing & certification",
                9: "Prototype — Specific Tests"
            } 
        }
    ];

    const [progresses, setProgresses] = useState(Array(items.length).fill(0)); // Track progress for each item
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const timelineBarRefs = useRef([]); // Array of refs for each timeline bar

    const handleDragStart = (index, event) => {
        event.preventDefault();

        const handleMouseMove = (e) => handleDrag(index, e);
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleDrag = (index, event) => {
        const timelineBar = timelineBarRefs.current[index];
        if (!timelineBar) return;

        const barRect = timelineBar.getBoundingClientRect();
        const newProgress = Math.min(
            Math.max(0, Math.round(((event.clientX - barRect.left) / barRect.width) * 10)),
            10
        );

        setProgresses((prev) => {
            const updatedProgresses = [...prev];
            updatedProgresses[index] = newProgress;
            return updatedProgresses;
        });
    };

    const handleLabelClick = (item) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    return (
        <div className="dashboard-container">
            {/* Top Section with Four Columns */}
            {/* Top Section with Timeline View and Indicators */}
            <div className="top-section">
                <div className="column toggle-column">
                    <label className="toggle-label">
                        <span>Timeline View</span>
                        <input type="checkbox" className="toggle-switch" />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                <div className="column indicator-column">
                    <div className="indicator">
                        <span className="checkpoint-dot"></span> Checkpoint
                    </div>
                </div>
                <div className="column indicator-column">
                    <div className="indicator">
                        <span className="current-status-diamond"></span> Current Status
                    </div>
                </div>
                <div className="column indicator-column">
                    <div className="indicator">
                        <span className="target-goal-diamond yellow"></span>
                        <span className="target-goal-diamond red"></span> Target Goal
                    </div>
                </div>
            </div>

            {/* Timeline Bars */}
            <div className="timeline-section">
                {items.map((item, index) => (
                    <div className="timeline-row" key={index}>
                        <div className="timeline-label" style={{ backgroundColor: item.background, borderColor: item.color, color: "#fff",cursor:'pointer' }} onClick={() => handleLabelClick(item)}>
                            {item.name} ({index + 1})
                        </div>
                        <div
                            className="timeline-bar"
                            ref={(el) => (timelineBarRefs.current[index] = el)}
                            style={{ background: `linear-gradient(to right, ${item.color}, #888)` }}
                        >
                            {[...Array(10)].map((_, i) => (
                                <span
                                    key={i}
                                    className={`checkpoint ${i <= progresses[index] ? 'active' : ''} ${i === progresses[index] ? 'current' : ''}`}
                                    style={{
                                        backgroundColor: i <= progresses[index] ? item.color : "#888",
                                    }}
                                    title={i <= progresses[index] && item.items ? item.items[i] : ''}
                                ></span>
                            ))}
                            <span
                                className="draggable-handle"
                                style={{ left: `${(progresses[index] / 9) * 99}%`, backgroundColor: item.color }}
                                onMouseDown={(event) => handleDragStart(index, event)}
                            >
                                <small className="handle-text">
                                    {item.items && item.items[progresses[index]] ? item.items[progresses[index]] : item.name}
                                </small>
                            </span>
                        </div>
                        {/* <div className="checkpoint-text">
                            {`Current Progress: Checkpoint ${progresses[index] + 1} for ${item.name}`}
                        </div> */}
                    </div>
                ))}
            </div>
            <ProgressModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                item={selectedItem}
            />
        </div>
    );
}

import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from './components/AdminLayout/AdminLayout';
import Login from './components/login/Login';
import SecondLogin from './components/SecondLogin/SecondLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { FileProvider } from './context/FileContext'; // Import the provider
import Register from './components/register/Register';
import Vendor from './components/vendor/Vendor';
import TempFileView from './components/common/TempFileView/TempFileView';


function App() {
  return (
    <FileProvider>
    <BrowserRouter>
     {/* Your ToastContainer component for handling toast notifications */}
     <ToastContainer />
      <Routes>
      <Route path="/vendor" element={<Vendor />} />
      <Route path="/file/:fileId" element={<TempFileView />} /> {/* Add this route */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/second-login" element={<SecondLogin />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
    </FileProvider>
  
  );
}

export default App;

import React, { useEffect } from 'react';
import './CosmicLoader.css'; // Move the CSS from your HTML to a separate file

const CosmicLoader = () => {
  useEffect(() => {
    // Function to create stars dynamically
    const createStars = () => {
      const starsContainer = document.createElement('div');
      starsContainer.className = 'stars';

      for (let i = 0; i < 100; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        star.style.setProperty('--twinkle-duration', `${1 + Math.random() * 3}s`);
        starsContainer.appendChild(star);
      }

      document.body.appendChild(starsContainer);

      // Cleanup: Remove stars when component unmounts
      return () => {
        document.body.removeChild(starsContainer);
      };
    };

    createStars();
  }, []);

  return (
    <>
    <div className="cosmic-loader">
      <div className="orbit">
        <div className="satellite"></div>
      </div>
      <div className="orbit">
        <div className="satellite"></div>
      </div>
      <div className="orbit">
        <div className="satellite"></div>
      </div>
      <div className="core"></div>
    </div>
    <div className="loading-text">Loading ...</div>
    </>
  );
};

export default CosmicLoader;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import './SubChildCategory.css';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../config/config';

export default function SubChildCategory() {
  const [data, setData] = useState({});
  const [subChildCategories, setSubChildCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [combinedDropdown, setCombinedDropdown] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedEntity, setSelectedEntity] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    status: 'active',
    category_name: '',
    sub_category_name: '',
    subchild_category_name: '',
  });
  const [editId, setEditId] = useState(null);

  const SUBCHILD_CATEGORY_API = `${API_BASE_URL}/subchild-category`;
  const LAYERS_CATEGORIES_API = `${API_BASE_URL}/layers-categories`;

  // Fetch sub-child categories
  const fetchSubChildCategories = async () => {
    try {
      const response = await axios.get(SUBCHILD_CATEGORY_API);
      setSubChildCategories(response.data);
    } catch (error) {
      console.error('Error fetching sub-child categories:', error);
    }
  };

  // Fetch category and layer data
  const fetchCategoryData = async () => {
    try {
      const response = await axios.get(LAYERS_CATEGORIES_API);
      setData(response.data);
      setCategories(Object.keys(response.data));
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  // Initialize data
  useEffect(() => {
    fetchCategoryData();
    fetchSubChildCategories();
  }, []);

  // Handle category change
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);

    const selectedData = data[categoryId];
    if (selectedData) {
      const combined = [
        ...(selectedData.layers || []),
        ...(selectedData.components || []),
        ...(selectedData.subsystems || []),
      ];
      setCombinedDropdown(combined);
    } else {
      setCombinedDropdown([]);
    }

    setSubCategories([]);
    setSelectedEntity('');
    setSelectedSubCategory('');
    setFormData({ ...formData, category_name: categoryId, sub_category_name: '', subchild_category_name: '' });
  };

  // Handle entity change (Layers, Components, or Subsystems)
  const handleEntityChange = (e) => {
    const entityId = e.target.value;
    const selectedEntityData = combinedDropdown.find((entity) => entity.id === entityId);
    setSelectedEntity(entityId);
    setSubCategories(selectedEntityData?.subcategories || []);
    setFormData({ ...formData, sub_category_name: entityId, subchild_category_name: '' });
  };

  // Handle subcategory change
  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setSelectedSubCategory(subCategoryId);
    setFormData({ ...formData, subchild_category_name: subCategoryId });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Open modal for creating or editing
  const openModal = (data = null) => {
    if (data) {
      setEditId(data.id);
      setFormData({
        name: data.name,
        description: data.description,
        status: data.status,
        category_name: data.category_name,
        sub_category_name: data.sub_category_name,
        subchild_category_name: data.subchild_category_name,
      });
    } else {
      setEditId(null);
      setFormData({
        name: '',
        description: '',
        status: 'active',
        category_name: '',
        sub_category_name: '',
        subchild_category_name: '',
      });
      setSelectedCategory('');
      setSelectedEntity('');
      setSelectedSubCategory('');
    }
    setShowModal(true);
  };

  // Handle form submission for creating or updating
  const handleSave = async () => {
    try {
      if (editId) {
        await axios.put(`${SUBCHILD_CATEGORY_API}/${editId}`, formData);
      } else {
        await axios.post(SUBCHILD_CATEGORY_API, formData);
      }
      toast.success( editId ? 'Updated Successfully' : 'Created Successfully'); // Success message
      fetchSubChildCategories();
      setShowModal(false);
    } catch (error) {
      console.error('Error saving sub-child category:', error);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${SUBCHILD_CATEGORY_API}/${id}`);
      toast.success('SubChildCat deleted successfully'); // Success message
      fetchSubChildCategories();
    } catch (error) {
      toast.error('something went try Again'); // Error message
      console.error('Error deleting sub-child category:', error);
    }
  };

  return (
    <Container fluid className="subchild-category-container">
      <Row className="top-section mb-4">
        <Col md={10}>
          <h3>Sub-Child Categories</h3>
        </Col>
        <Col className="text-end">
          <Button variant="light" onClick={() => openModal()} className="d-flex justify-content-end align-items-center division-buttons">
            Add Sub-Child Category
          </Button>
        </Col>
      </Row>
      <div className="subchild-category-divider mb-4"></div>
      <Table bordered hover className="subchild-category-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Category</th>
            <th>Entity</th>
            <th>Subcategory</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subChildCategories.map((item) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>{item.status}</td>
              <td>{item.category_name}</td>
              <td>{item.sub_category_name}</td>
              <td>{item.subchild_category_name}</td>
              <td>
                <Button variant="warning" size="sm" onClick={() => openModal(item)} className="me-2">
                  Edit
                </Button>
                <Button variant="danger" size="sm" onClick={() => handleDelete(item._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Create/Edit */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editId ? 'Edit Sub-Child Category' : 'Add Sub-Child Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              {/* <Form.Label>Category</Form.Label> */}
              <Form.Label>Model</Form.Label>
              <Form.Control as="select" value={selectedCategory} onChange={handleCategoryChange} required>
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Entity (Layers/Components/Subsystems)</Form.Label>
              <Form.Control as="select" value={selectedEntity} onChange={handleEntityChange} required>
                <option value="">Select Entity</option>
                {combinedDropdown.map((entity) => (
                  <option key={entity.id} value={entity.id}>
                    {entity.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subcategory</Form.Label>
              <Form.Control as="select" value={selectedSubCategory} onChange={handleSubCategoryChange} required>
                <option value="">Select Subcategory</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" name="description" value={formData.description} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" name="status" value={formData.status} onChange={handleInputChange}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

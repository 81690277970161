import React from 'react';
import { Form } from 'react-bootstrap';

const FileTypeDropdown = ({ value, onChange, errors }) => {
  // MIME type to file extension mapping
  const mimeMap = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
    'image/tiff': 'tiff',
    'image/svg+xml': 'svg',
    'image/x-icon': 'ico',

    'video/mp4': 'mp4',
    'video/mpeg': 'mpeg',
    'video/quicktime': 'mov',
    'video/x-msvideo': 'avi',
    'video/x-matroska': 'mkv',
    'video/webm': 'webm',
    'video/3gpp': '3gp',
    'video/3gpp2': '3g2',
    'video/x-flv': 'flv',
    'video/x-ms-wmv': 'wmv',

    'model/gltf+json': 'gltf',
    'model/gltf-binary': 'glb',
    'model/obj': 'obj',
    'model/stl': 'stl',
    'model/ply': 'ply',
    'model/vnd.collada+xml': 'dae',
    // 'model/mtl': 'mtl',
    // 'application/x-blender': 'blend',

    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel.sheet.macroenabled.12': 'xlsm',
    'text/csv': 'csv',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',

    'text/plain': 'txt',
    'text/html': 'html',
    'application/json': 'json',
    'application/xml': 'xml',
  };

  // Categorize MIME types
  const categorizedMimeMap = {
    image: Object.entries(mimeMap).filter(([key]) => key.startsWith('image')).map(([, ext]) => ext),
    video: Object.entries(mimeMap).filter(([key]) => key.startsWith('video')).map(([, ext]) => ext),
    '3dModel': Object.entries(mimeMap).filter(([key]) => key.startsWith('model')).map(([, ext]) => ext),
    document: Object.entries(mimeMap).filter(([key]) => key.startsWith('application')).map(([, ext]) => ext),
    miscellaneous: Object.entries(mimeMap).filter(([key]) => key.startsWith('text')).map(([, ext]) => ext),
  };

  // Create concise labels for display
  const getLabel = (category, extensions) => {
    return `${category} (${extensions.join(', ')})`;
  };

  return (
    <Form.Group controlId="fileType" className="mt-3">
      <Form.Label>File Type</Form.Label>
      <Form.Control
        as="select"
        name="file_type"
        value={value}
        onChange={onChange}
        required
        isInvalid={!!errors}
      >
        <option value="">Select File Type</option>
        {Object.entries(categorizedMimeMap).map(([category, extensions]) => (
          <option key={category} value={category}>
            {getLabel(category, extensions)}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FileTypeDropdown;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './TempFileView.css';
import FilePreview from '../FilePreview/FilePreview';
import PreviewModal from '../PreviewModal/PreviewModal';
import { API_BASE_URL } from '../../../config/config';

const TempFileView = () => {
  const [tempFile, setTempFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState({ show: false, fileType: '', fileUrl: '' });
  const [error, setError] = useState(null);
  const { fileId } = useParams(); // Extract fileId from the URL

  useEffect(() => {
    // Fetch the file record from the API
    const fetchFileRecord = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/temporary/${fileId}`);
        setTempFile(response.data.tempFile);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching file record:', err);
        setError('Failed to fetch file record.');
        setLoading(false);
      }
    };

    fetchFileRecord();
  }, [fileId]);

  const handlePreview = (fileType, fileUrl) => {
    console.log("fileUrl", fileUrl)
    setPreview({ show: true, fileType, fileUrl });
  };

  if (loading) return <div className="loading-screen">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  const { file_type, file_path, file_name, vendor_name, vendor_email, path, file_url } = tempFile;
  // const fileUrl = `https://dev.urps.kosmosuit.com/${file_path.replace(/\\/g, '/')}`;
  const fileUrl = file_url;

  // const renderPreview = () => {
  //   if (file_type === 'image') {
  //     return <img src={fileUrl} alt={file_name} className="file-preview" />;
  //   }

  //   if (file_type === 'video') {
  //     return (
  //       <video controls className="file-preview">
  //         <source src={fileUrl} type="video/mp4" />
  //         Your browser does not support the video tag.
  //       </video>
  //     );
  //   }

  //   if (file_type === '3dModel') {
  //     return (
  //       <iframe
  //         title="3D Model Viewer"
  //         src={fileUrl}
  //         className="file-preview"
  //       ></iframe>
  //     );
  //   }

  //   if (['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'].includes(file_type)) {
  //     return (
  //       <iframe
  //         title="Document Viewer"
  //         src={`https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`}
  //         className="file-preview"
  //       ></iframe>
  //     );
  //   }

  //   return (
  //     <a href={fileUrl} download className="file-download-link">
  //       Download File
  //     </a>
  //   );
  // };

  return (
    <div className="temp-file-view-container">
      <div className="background-image"></div>
      <div className="background-overlay"></div>
      <div className="file-details-card">
        <h2 className="title">File Details</h2>
        <p>
          <strong>File Name:</strong> {file_name}
        </p>
        <p>
          <strong>Vendor Name:</strong> {vendor_name}
        </p>
        <p>
          <strong>Vendor Email:</strong> {vendor_email}
        </p>
        <p>
          <strong>Path:</strong> {path}
        </p>
        <div>
          <h3>Preview:</h3>
          <FilePreview
                  fileType={file_type}
                  fileUrl={fileUrl}
                  onPreview={(type, url) => handlePreview(type, url)} // Pass preview handler
                />
          {/* {renderPreview()} */}
        </div>
      </div>
      <PreviewModal
              show={preview.show}
              handleClose={() => setPreview({ ...preview, show: false })}
              fileType={preview.fileType}
              fileUrl={preview.fileUrl}
            />
    </div>
  );
};

export default TempFileView;

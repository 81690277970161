
// https://www.figma.com/design/oDlT1OimVg63o6Q86s1WsQ/Dev?m=auto&t=euWWrw6yOofko3ZW-1

// http://74.249.57.176/
// rooturpsdev
// Ramesh123456789!@#$%^&
// X8DJTE9UEU9LCCW4A4T2KNA2
//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

import Home from './components/views/Home/Home';

import Calendar from './components/views/Calendar/Calendar';

import Requirements from './components/views/Requirements/Requirements';
import SafetyRequirement from './components/views/Requirements/SafetyRequirement/SafetyRequirement';
import Account from './components/views/Account/Account';
import Maintenance from './components/views/Maintenance/Maintenance';
import Routing from './components/views/Production/Routings/Routing';
import BillOfMaterials from './components/views/Production/BillOfMaterials/BillOfMaterials';
import Order from './components/views/Production/Orders/Order';

import Design from './components/views/Design/Design';
import DesignStages from './components/views/Design/DesignStages/DesignStages';
import UploadDesign from './components/views/Design/UploadDesign/UploadDesign';

import Engineering from './components/views/Engineering/Engineering';
import EngineeringScreen from './components/views/Engineering/EngineeringScreen/EngineeringScreen';

import QualityTesting from './components/views/QualityTesting/QualityTesting';
import QualityTestingOption from './components/views/QualityTesting/QualityTestingOptions/QualityTestingOption';
import QualityTestingManually from './components/views/QualityTesting/QualityTestingManually/QualityTestingManually';

import FileDropZone from './components/common/FileDropZone/FileDropZone';

import Division from './components/views/Requirements/Division/Division';
import SubDivision from './components/views/Requirements/SubDivision/SubDivision';
import NewRequirement from './components/views/Requirements/NewRequirment/NewRequirment';

import Dashboard from './components/views/Dashboard/Dashboard';
import Calls from './components/views/Calls/Calls';
import SubChildCategory from './components/views/Categories/SubChildCategory/SubChildCategory';
import TreeViewCategories from './components/common/TreeViewCategories/TreeViewCategories';
import GoogleDrive from './components/common/GoogleDrive/GoogleDrive';
import RequirementsList from './components/views/Requirements/NewRequirment/RequirementsList';
import CreateOrUpdateRequirement from './components/views/Requirements/NewRequirment/CreateOrUpdateRequirement';
import EngineeringList from './components/views/Engineering/EngineeringList/EngineeringList';
import CreateOrUpdateEngineering from './components/views/Engineering/CreateOrUpdateEngineering/CreateOrUpdateEngineering';
import UploadDesignList from './components/views/Design/UploadDesign/UploadDesignList/UploadDesignList';
import CreateOrUpdateUploadDesign from './components/views/Design/UploadDesign/CreateOrUpdateUploadDesign/CreateOrUpdateUploadDesign';
import CalendarOutLook from './components/views/Calendar/CalendarOutLook';
import LighttingChartAirplane from './components/views/Calls/LighttingChartAirplane';
import SensorSuitGraph from './components/views/Calls/SensorSuitGraph';

const routes = [

  {
    path: "/file-drop-zone",
    name: "File Drop Zone",
    icon: "tim-icons icon-bell-55",
    component: <FileDropZone />,
    layout: "/admin",
    showInSidebar: false,
    
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "home.png",
    component: <Dashboard   />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/home",
    name: "Home",
    icon: "home.png",
    component: <Home />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "calendar.png",
    component: <CalendarOutLook />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/calls",
    name: "Calls",
    icon: "call.png",
    component: <Calls   />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/messages",
    name: "Messages",
    icon: "message.png",
    component: <Calls   />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/requirements",
    name: "Requirments",
    icon: "requirment.png",
    component: <RequirementsList   />,
    layout: "/admin",
    showInSidebar: true,
  },

  {
    path: "/requirements/new",
    name: "New Requirement",
    icon: "tim-icons icon-simple-add",
    component: <CreateOrUpdateRequirement />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/requirements/edit/:id",
    name: "Edit Requirement",
    icon: "tim-icons icon-pencil",
    component: <CreateOrUpdateRequirement />,
    layout: "/admin",
    showInSidebar: false,
  },
  // {
  //   path: "/requirements",
  //   name: "Requirments",
  //   icon: "requirment.png",
  //   component: <Requirements />,
  //   layout: "/admin",
  //   showInSidebar: true,
  // },
  {
    path: "/safety-requirments",
    name: "Safety Requirments",
    icon: "tim-icons icon-bell-55",
    component: <SafetyRequirement />,
    layout: "/admin",
    showInSidebar: false,
  },
  // {
  //   path: "/design",
  //   name: "Design",
  //   icon: "design.png",
  //   component: <Design />,
  //   layout: "/admin",
  //   showInSidebar: true,
  // },
  {
    path: "/design",
    name: "Design",
    icon: "design.png",
    component: <UploadDesignList />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/upload-design/new",
    name: "Upload Design",
    icon: "design.png",
    component: <CreateOrUpdateUploadDesign />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/upload-design/edit/:id",
    name: "Upload Design",
    icon: "design.png",
    component: <CreateOrUpdateUploadDesign />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/design-stages",
    name: "Design Stages",
    icon: "design.png",
    component: <DesignStages />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/engineering",
    name: "Engineering",
    icon: "engineering.png",
    component: <EngineeringList />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/engineering/new",
    name: "Engineering",
    icon: "engineering.png",
    component: <CreateOrUpdateEngineering />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/engineering/edit/:id",
    name: "Edit Engineering",
    icon: "engineering.png",
    component: <CreateOrUpdateEngineering />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/engineering-screen",
    name: "Engineering Screen",
    icon: "engineering.png",
    component: <EngineeringScreen />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/production-routing",
    name: "Production",
    icon: "production.png",
    component: <Routing />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/production-order",
    name: "Production Order",
    icon: "production.png",
    component: <Order />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/production-bill-of-materials",
    name: "Production BillOfMaterials",
    icon: "production.png",
    component: <BillOfMaterials />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/quality-testing",
    name: "Quality & Testing",
    icon: "quality.png",
    component: <QualityTesting />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/quality-testing-option",
    name: "Quality Testing Option",
    icon: "quality.png",
    component: <QualityTestingOption />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/quality-testing-manually",
    name: "Quality Testing Manually",
    icon: "quality.png",
    component: <QualityTestingManually />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    icon: "maintenance.png",
    component: <Maintenance />,
    layout: "/admin",
    showInSidebar: true,
  },
  {
    path: "/account",
    name: "Account",
    icon: "account.png",
    component: <Account />,
    layout: "/admin",
    showInSidebar: true,
  },
  
  // {
  //   path: "/upload-design",
  //   name: "Upload Design",
  //   icon: "tim-icons icon-bell-55",
  //   component: <UploadDesign />,
  //   layout: "/admin",
  // },
  

  // {
  //   path: "/engineering",
  //   name: "Engineering",
  //   icon: "tim-icons icon-bell-55",
  //   component: <Engineering />,
  //   layout: "/admin",
  // },

  {
    path: "/divisions",
    name: "Divisions",
    icon: "tim-icons icon-bell-55",
    component: <Division   />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/subdivisions",
    name: "SubDivisions",
    icon: "tim-icons icon-bell-55",
    component: <SubDivision   />,
    layout: "/admin",
    showInSidebar: false,
  },
  // {
  //   path: "/newrequirement",
  //   name: "New Requirment",
  //   icon: "tim-icons icon-bell-55",
  //   component: <NewRequirement   />,
  //   layout: "/admin",

  // },

  

  // {
  //   path: "/calls",
  //   name: "Calls",
  //   icon: "tim-icons icon-bell-55",
  //   component: <GoogleDrive   />,
  //   layout: "/admin",
  
  // },


  {
    path: "/sensor",
    name: "Sensor",
    icon: "tim-icons icon-bell-55",
    component: <SensorSuitGraph   />,
    layout: "/admin",
    showInSidebar: false,
  },

  {
    path: "/light-chart",
    name: "Light Chart",
    icon: "tim-icons icon-bell-55",
    component: <LighttingChartAirplane   />,
    layout: "/admin",
    showInSidebar: false,
  },


  {
    path: "/categories",
    name: "Categories",
    icon: "tim-icons icon-bell-55",
    component: <SubChildCategory   />,
    layout: "/admin",
    showInSidebar: false,
  },

  {
    path: "/tree-view",
    name: "Treeview",
    icon: "tim-icons icon-bell-55",
    component: <TreeViewCategories   />,
    layout: "/admin",
    showInSidebar: false,
  },

  {
    path: "/categories",
    name: "Categories",
    icon: "tim-icons icon-bell-55",
    component: <SubChildCategory   />,
    layout: "/admin",
    showInSidebar: false,
  },
];

export default routes;

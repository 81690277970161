import React, { useState } from 'react';
import { Modal, Table, Button, Row, Col } from 'react-bootstrap';
import './ProgressModal.css';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register components with Chart.js
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const ProgressModal = ({ show, handleClose, item }) => {
    const [checkpoint, setCheckpoint] = useState(null);

    if (!item) return null;

    // Data for Chart.js Line Chart
    const data = {
        labels: [
            "Technical Feasibility Study", "Concept", "Conops", "MBSE Framework", 
            "Requirements", "Design", "3D CAD", "Sub-Assembly Design",
            "Engineering", "Eng. Product Data", "Eng. Simulations", "Design for Manufacturing",
            "Manufacturing", "Testing & certification", "Prototype", "Specific Testing", "Space Certification"
        ],
        datasets: [
            {
                label: 'Weeks Over Expected',
                data: [3, -3, -2, 0, 2, 4, 4, 6, 3, -5, 0, 1, 2, 5, 4, 3, 1],
                borderColor: '#FF0000',
                backgroundColor: '#FF0000',
                pointBackgroundColor: (context) => {
                    const index = context.dataIndex;
                    if (index === 0 || index === 1) {
                        return 'purple';
                    } else if (index === 6) {
                        return 'red';
                    } else {
                        return 'yellow';
                    }
                },
                pointRadius: 6,
                pointHoverRadius: 8,
                borderWidth: 2,
                tension: 0.3,
            },
        ],
    };

    // Options for Chart.js Line Chart
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: false,
                min: -6,
                max: 9,
                title: {
                    display: true,
                    text: 'Weeks Over Expected',
                    color: '#ffffff',
                },
                grid: {
                    color: '#444',
                },
                ticks: {
                    color: '#ffffff',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Stages',
                    color: '#ffffff',
                },
                ticks: {
                    color: '#ffffff',
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                },
                grid: {
                    color: '#444',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const elementIndex = elements[0].index;
                setCheckpoint({
                    index: elementIndex,
                    label: data.labels[elementIndex],
                    dataPoint: data.datasets[0].data[elementIndex],
                });
            }
        },
    };

    // Recent Uploads Data (Example Data)
    const recentUploads = [
        { name: 'SensorLayer.V1', employee: 'Alex L', goal: '06/21/2024', uploadDate: '06/24/2024', timeOver: '+3 days over' },
        { name: 'Biosensor.Integration', employee: 'Ashley K', goal: '05/16/2024', uploadDate: '05/04/2024', timeOver: '-12 days early' },
        { name: 'Diagnostics.Interface', employee: 'Michelle P', goal: '05/01/2024', uploadDate: '05/01/2024', timeOver: 'On Time' },
        { name: 'VitalStats.Core', employee: 'Ashley K', goal: '04/20/2024', uploadDate: '04/31/2024', timeOver: '+11 days over' }
    ];

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{item.name} (1st Layer)</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-light">
                    <div className="modal-info">
                        <div className="modal-text">
                            <Row>
                                <Col md={4}>
                                    <p>Status: <span style={{ color: '#00ff00' }}>On Track</span></p>
                                </Col>
                                <Col md={4}>
                                    <p>Started: <span>02/17/2023</span></p>
                                </Col>
                                <Col md={4}>
                                    <p>Projected End Date: <span>06/31/2023</span></p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="chart-section mt-4 mb-4">
                        <Line data={data} options={options} width={'800px'} />
                    </div>
                    <h5 className="text-uppercase">Recent Uploads</h5>
                    <Table striped bordered hover variant="dark" className="mt-3">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Employee</th>
                                <th>Goal</th>
                                <th>Upload Date</th>
                                <th>Time Over Expected</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentUploads.map((upload, idx) => (
                                <tr key={idx}>
                                    <td>{upload.name}</td>
                                    <td>{upload.employee}</td>
                                    <td>{upload.goal}</td>
                                    <td>{upload.uploadDate}</td>
                                    <td>{upload.timeOver}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Checkpoint Modal */}
            {checkpoint && (
                <Modal show={true} onHide={() => setCheckpoint(null)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{checkpoint.label} - Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="bg-dark text-light">
                        <p>{checkpoint.dataPoint} Days past goal</p>
                        <p>Start: 08/03/24</p>
                        <p>Goal: 08/23/24</p>
                        <p>Completion: 09/07/24</p>
                        <h5 className="text-uppercase mt-3">Activity</h5>
                        <Table striped bordered hover variant="dark" className="mt-3">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th>Time Over</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span role="img" aria-label="avatar">🟦</span> "Final Sketch"</td>
                                    <td>08/11/23</td>
                                    <td>+2d</td>
                                </tr>
                                <tr>
                                    <td><span role="img" aria-label="avatar">🟪</span> "Exploratory Sketches"</td>
                                    <td>08/03/23</td>
                                    <td>+3d</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setCheckpoint(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ProgressModal;

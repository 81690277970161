import React, { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

function FileUpload({ onFileSelect, selectedFile }) {
    const [showFileSourceModal, setShowFileSourceModal] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedFileSource, setSelectedFileSource] = useState(null);

    const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
    const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            onFileSelect(e.target.files[0]);
        }
    };

    const handleFileSourceSelect = (source) => {
        setSelectedFileSource(source);
        setShowFileSourceModal(false);

        if (source === 'pc') {
            if (fileInputRef.current) {
                fileInputRef.current.click();
            } else {
                console.error('File input ref is not available');
            }
        } else if (source === 'google-drive') {
            handleGoogleDrivePicker();
        }
    };

    const handleGoogleDrivePicker = () => {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: async (response) => {
                if (response.error) {
                    console.error('Error:', response.error);
                    return;
                }
                const oauthToken = response.access_token;

                const picker = new window.google.picker.PickerBuilder()
                    .addView(window.google.picker.ViewId.DOCS)
                    .setOAuthToken(oauthToken)
                    .setDeveloperKey(API_KEY)
                    .setCallback(async (data) => {
                        if (data.action === window.google.picker.Action.PICKED) {
                            const file = data.docs[0];
                            try {
                                const fileBlob = await fetchGoogleDriveFile(file.id, oauthToken);
                                if (fileBlob) {
                                    onFileSelect(fileBlob);
                                }
                            } catch (error) {
                                console.error('Error fetching file blob:', error);
                            }
                        }
                    })
                    .build();

                picker.setVisible(true);
            },
        });
        tokenClient.requestAccessToken();
    };

    const fetchGoogleDriveFile = async (fileId, accessToken) => {
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Error fetching file from Google Drive, status: ${response.status}`);
            }

            return await response.blob();
        } catch (error) {
            console.error('Error downloading file:', error);
            return null;
        }
    };

    return (
        <>
            <Form.Group controlId="fileType" className="">
                <Form.Label>File</Form.Label>
                <div>
                    <Button className='form-control' onClick={() => setShowFileSourceModal(true)}>
                        Choose File
                    </Button>
                </div>
                {selectedFile && (
                    <div>
                        <p>Selected File: {selectedFile.name}</p>
                    </div>
                )}
                <input
                    className='form-control'
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </Form.Group>

            <Modal show={showFileSourceModal} onHide={() => setShowFileSourceModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select File Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-around">
                        <Button variant="primary" onClick={() => handleFileSourceSelect('pc')}>
                            Upload from PC
                        </Button>
                        <Button variant="secondary" onClick={() => handleFileSourceSelect('google-drive')}>
                            Select from Google Drive
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FileUpload;
import React, { useState, useEffect } from 'react';
import { Tree } from 'react-d3-tree';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/config';

export default function TreeViewCategories({ onSelect, onClose }) {
  const containerStyles = {
    width: '100%',
    height: '700px',
  };

  const [treeData, setTreeData] = useState([]); // Tree hierarchy
  const [prevSelectedParentNode, setPrevSelectedParentNode] = useState(null); // Previous selected node
  const [prevSelectedNode, setPrevSelectedNode] = useState(null); // Previous selected node
  const [prevSelectedPath, setPrevSelectedPath] = useState([]); // Previous selected path
  const [catData, setCatdata] = useState([]);

  useEffect(() => {
    const fetchAndInitializeTree = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/layers-categories`);
        const data = response.data;
        setCatdata(data);
        // Transform the API response into the tree structure
        const rootNode = {
          name: 'Kosmosuit System',
          id: 'root',
          _children: Object.keys(data).map((entityKey) => ({
            id: entityKey,
            name: entityKey.charAt(0).toUpperCase() + entityKey.slice(1),
            _children: [
              {
                name: 'Layers',
                id: `${entityKey}_layers`,
                _children: data[entityKey].layers.map((layer) => ({
                  id: layer.id,
                  name: layer.name,
                  label: layer.label,
                  _children: layer.subcategories.map((subcategory) => ({
                    id: subcategory.id,
                    name: subcategory.name,
                    _children: [],
                  })),
                })),
              },
              {
                name: 'Components',
                id: `${entityKey}_components`,
                _children: data[entityKey].components.map((component) => ({
                  id: component.id,
                  name: component.name,
                  label: component.label,
                  _children: component.subcategories.map((subcategory) => ({
                    id: subcategory.id,
                    name: subcategory.name,
                    _children: [],
                  })),
                })),
              },
              {
                name: 'Subsystems',
                id: `${entityKey}_subsystems`,
                _children: data[entityKey].subsystems.map((subsystem) => ({
                  id: subsystem.id,
                  name: subsystem.name,
                  label: subsystem.label,
                  _children: subsystem.subcategories.map((subcategory) => ({
                    id: subcategory.id,
                    name: subcategory.name,
                    _children: [],
                  })),
                })),
              },
            ],
          })),
        };

        setTreeData([rootNode]); // Initialize tree data with the formatted structure
      } catch (error) {
        console.error('Error fetching tree data:', error);
      }
    };

    fetchAndInitializeTree();
  }, []);

  // Recursive function to find and update the clicked node
  const updateNode = (node, nodeId, updater) => {
    if (node.id === nodeId) {
      return updater(node);
    }
    if (node.children) {
      return {
        ...node,
        children: node.children.map((child) => updateNode(child, nodeId, updater)),
      };
    }
    if (node._children) {
      return {
        ...node,
        _children: node._children.map((child) => updateNode(child, nodeId, updater)),
      };
    }
    return node;
  };

  // Handle node click: toggle and load children dynamically
  const handleNodeClick = async (nodeDatum) => {
    // console.log(nodeDatum);
    if (nodeDatum.__rd3t?.depth === 2) {
      setPrevSelectedParentNode(nodeDatum.id);
    }
    // console.log(prevSelectedParentNode)
    if (nodeDatum.__rd3t?.depth === 4) {
      if (onSelect) {
        const parentcategory = prevSelectedParentNode;
        const category = prevSelectedNode;
        const subcategory = nodeDatum.id;
        onSelect({catData,parentcategory,category,subcategory});
      }
      if (onClose) {
        onClose();
      }
      return;
    }

    setPrevSelectedNode(nodeDatum.id);

    const updatedTreeData = treeData.map((rootNode) =>
      updateNode(rootNode, nodeDatum.id, (node) => {
        if (node.children) {
          // Collapse the node
          return {
            ...node,
            _children: node.children,
            children: null,
          };
        }
        if (node._children && node._children.length > 0) {
          // Expand pre-loaded children
          return {
            ...node,
            children: node._children,
            _children: null,
          };
        }
        return node; // Return unchanged if no children to toggle
      })
    );
    setTreeData(updatedTreeData); // Update state to trigger re-render
  };

  // Helper to get the full path of a clicked node
  const getNodePath = (nodeDatum, path = []) => {
    path.unshift(nodeDatum.name);
    if (nodeDatum.parent) {
      return getNodePath(nodeDatum.parent, path);
    }
    return path;
  };

  // Render custom nodes with click handling
  const renderCustomNode = ({ nodeDatum }) => (
    <g onClick={() => handleNodeClick(nodeDatum)}>
      <rect
        width="150"
        height="60"
        x="-75"
        y="-30"
        fill="#8A34FF"
        rx="10"
        stroke="#717171"
        strokeWidth="2"
      />
      <text
        fill="#ffffff"
        x="0"
        y="0"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="12"
        fontWeight="bold"
      >
        {nodeDatum.name}
      </text>
    </g>
  );

  return (
    <div style={containerStyles}>
      {treeData.length > 0 && (
        <Tree
          data={treeData}
          orientation="vertical"
          translate={{ x: 300, y: 50 }}
          renderCustomNodeElement={(rd3tProps) => renderCustomNode(rd3tProps)}
          collapsible={false} // Manual collapsibility
          separation={{ siblings: 1.5, nonSiblings: 2 }}
        />
      )}
    </div>
  );
}

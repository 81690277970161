export const getFileExtension = (mimeType) => {
    const mimeMap = {
      // Images
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/webp': 'webp',
      'image/tiff': 'tiff',
      'image/svg+xml': 'svg',
      'image/x-icon': 'ico',
  
      // Videos
      'video/mp4': 'mp4',
      'video/mpeg': 'mpeg',
      'video/quicktime': 'mov',
      'video/x-msvideo': 'avi',
      'video/x-matroska': 'mkv',
      'video/webm': 'webm',
      'video/3gpp': '3gp',
      'video/3gpp2': '3g2',
      'video/x-flv': 'flv',
      'video/x-ms-wmv': 'wmv',
  
      // 3D Models
      'model/gltf+json': 'gltf',
      'model/gltf-binary': 'glb',
      'model/obj': 'obj',
      'model/stl': 'stl',
      'model/mtl': 'mtl',
      'application/vnd.ms-ply': 'ply',
      'application/x-blender': 'blend',
      'model/vnd.collada+xml': 'dae',
  
      // Audio
      'audio/mpeg': 'mp3',
      'audio/ogg': 'ogg',
      'audio/wav': 'wav',
      'audio/x-flac': 'flac',
      'audio/aac': 'aac',
      'audio/webm': 'weba',
  
      // Documents
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  
      // Archives
      'application/zip': 'zip',
      'application/x-rar-compressed': 'rar',
      'application/x-7z-compressed': '7z',
      'application/x-tar': 'tar',
  
      // Miscellaneous
      'text/plain': 'txt',
      'text/html': 'html',
      'application/json': 'json',
      'application/xml': 'xml',
    };
  
    return mimeMap[mimeType] || ''; // Return an empty string if MIME type is not recognized
  };
  